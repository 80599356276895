import React, { useState } from "react";

import { renameOrganizationAPI } from "utils/organization/api";

import Dialog, { CloseDialog } from "components/Dialog";
import Input from "components/Input";
import Button from "components/Button";

import classes from "./style.module.scss";

const RenameOrganizationModal = ({ open, onClose, orgId, uid, oldName = "", fetchUserOrganization, setAlert }) => {
    const [newName, setNewName] = useState(oldName);
    const [loading, setLoading] = useState(false);

    const handleRename = async (e) => {
        e.preventDefault();

        document.body.style.cursor = "progress";

        setLoading(true);

        await renameOrganizationAPI({
            orgId,
            changeBy: uid,
            newName,
        });

        await fetchUserOrganization();

        document.body.style.cursor = "default";

        setLoading(false);

        setAlert({
            open: true,
            message: "Organization renamed successfully",
            anchorOrigin: { vertical: "top", horizontal: "center" },
            direction: "down",
        });

        onClose();
    };

    return (
        <Dialog open={open}>
            <CloseDialog closeActions={onClose} />
            <div className={classes.dialog}>
                <h3>Rename Organization</h3>
                <Input input={{ value: newName, onChange: (e) => setNewName(e) }} />
                <div className={classes.footer}>
                    <Button onClick={onClose} type={"red"}>
                        Cancel
                    </Button>
                    <Button onClick={handleRename} disabled={loading || newName.length < 3}>
                        {loading ? "Renaming..." : "Rename"}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default RenameOrganizationModal;
