import React, { useEffect, useRef, useState } from "react";
import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";
import { getValidation, required, maxLength } from "utils/fieldValidation";
import Accordion from "components/Accordion";
import FloorHeaderIcon from "assets/images/components/FloorHeader.svg";
import Actions from "../Actions/container";
import InputRow from "components/Input/Row";
import Select from "components/Input/Select";
import Compass from "components/Compass";
import { Field } from "redux-form";
import { getOptions } from "utils/fields";
import Measurements from "./Measurements/container";
import Construction from "./Construction/container";
import FloorHeaderStats from "./FloorHeaderStats/container";
import ComponentHeading from "../ComponentHeading";
import { isEqual } from "lodash";

const charLim32 = maxLength(32);
const headerLabelValidation = [required, charLim32, ...getValidation("floorHeaderLabel")];

const orientationValidation = getValidation("floorHeaderDirection");

export default React.memo(
    ({
        accessor,
        label = "",
        change,
        componentId,
        isNew = false,
        isRecentDuplicate = false,
        parentPath = "",
        isDragging,
        dragHandleProps,
        floorHeaderFacingId,
        parentFacingId,
        floorHeaderPerimeter,
        parentPerimeter,
        dragActive,
        clearNewComponent,
        drawingLines,
        contentOpen,
        dropPosition,
    }) => {
        const isDisableDir = [1, 2, 3, 4, 5, 6, 7, 8].includes(parentFacingId);
        const [nameDialogOpen, toggleNameDialog] = useState(false);

        useEffect(() => {
            if (floorHeaderPerimeter === -1) {
                change(`${accessor}.measurements.perimeter`, parentPerimeter);
            }
        }, []);

        return (
            <div
                className={`${classes.floorHeader} ${
                    dropPosition === "after"
                        ? sharedClasses.activeAfter
                        : dropPosition === "before"
                        ? sharedClasses.activeBefore
                        : ""
                }  `}
            >
                <Accordion
                    className={sharedClasses.accordion}
                    heading={
                        <ComponentHeading
                            nameField={{
                                name: `${accessor}.label`,
                                validate: headerLabelValidation,
                                current: label,
                                change,
                            }}
                            type="floorHeader"
                            componentId={componentId}
                            icon={FloorHeaderIcon}
                        />
                    }
                    initOpenState={isNew && !isRecentDuplicate}
                    isNew={isNew}
                    isDragging={isDragging}
                    dragActive={dragActive}
                    toggleNew={() => clearNewComponent(componentId)}
                    hoverActions={
                        <Actions
                            nameField={{
                                name: `${accessor}.label`,
                                validate: headerLabelValidation,
                                current: label,
                                change,
                            }}
                            label="Floor Header"
                            type="floorHeader"
                            parentPath={parentPath}
                            componentId={componentId}
                            dragHandleProps={dragHandleProps}
                            drawingLines={drawingLines}
                            change={change}
                            nameDialogOpen={nameDialogOpen}
                            toggleNameDialog={toggleNameDialog}
                        />
                    }
                    stats={() => <FloorHeaderStats accessor={accessor} />}
                    forceOpen={contentOpen}
                >
                    <h4>Measurements</h4>
                    <Measurements accessor={accessor} change={change} />
                    <h4>Construction</h4>
                    <Construction accessor={accessor} change={change} id={componentId} />
                    <InputRow gridTemplate="31.85% auto">
                        <div className={classes.orientation}>
                            <Compass
                                facingId={floorHeaderFacingId}
                                disabled={isDisableDir}
                                rotationOptions={{
                                    0: 0,
                                    1: 180,
                                    2: 135,
                                    3: 90,
                                    4: 45,
                                    5: 0,
                                    6: -45,
                                    7: -90,
                                    8: -135,
                                    9: 0,
                                }}
                            />
                            <Field
                                component={Select}
                                name={`${accessor}.facingDirection`}
                                options={getOptions({
                                    fieldKey: "floorHeaderDirection",
                                })}
                                label="Facing Direction"
                                placeholder="Choose Facing Direction"
                                validate={orientationValidation}
                                disabled={isDisableDir}
                            />
                        </div>
                    </InputRow>
                </Accordion>
            </div>
        );
    },
    isEqual
);
