import React, { useState } from "react";

import { DashboardAPI } from "utils/api";

import Dialog, { CloseDialog } from "components/Dialog";
import Input from "components/Input";
import Button from "components/Button";

import classes from "./styles.module.scss";

const TransferCreditsModal = ({
    credits,
    isOpen,
    closeModal,
    transferTo,
    transferToName,
    setAlert,
    transferFrom,
    organizationId,
    updateUser,
}) => {
    const [creditsToTransfer, setCreditsToTransfer] = useState(0);
    const [isTransferring, setIsTransferring] = useState(false);

    const handleClose = () => {
        setCreditsToTransfer(0);
        closeModal();
    };

    const handleTransfer = async (e) => {
        try {
            e.preventDefault();

            setIsTransferring(true);

            await DashboardAPI({
                method: "POST",
                url: "/transferCredits",
                data: {
                    transferFrom,
                    transferTo,
                    amount: creditsToTransfer,
                    organizationId,
                },
            });

            setAlert({
                open: true,
                message: `Successfully transferred ${creditsToTransfer} credits to ${transferToName}`,
                anchorOrigin: { vertical: "top", horizontal: "center" },
                direction: "down",
                severity: "success",
            });
            setIsTransferring(false);
            handleClose();
            updateUser({
                creditSummary: {
                    snapParametric: 0,
                    total: credits - creditsToTransfer,
                },
            });
        } catch (error) {
            setAlert({
                open: true,
                message: "An error occurred while transferring credits",
                anchorOrigin: { vertical: "top", horizontal: "center" },
                direction: "down",
                severity: "error",
            });
            setIsTransferring(false);
            handleClose();
        }
    };

    return (
        <Dialog open={isOpen}>
            <CloseDialog closeActions={handleClose} />
            <div className={classes.modalContainer}>
                <h2 className={classes.modalTitle}>Transfer Credits</h2>
                <div className={classes.modalBodyContainer}>
                    <p>
                        You have {credits} credits available. How many would you like to transfer to{" "}
                        <strong>{transferToName}</strong>?
                    </p>
                    <Input
                        type="number"
                        label="Credits to transfer"
                        input={{
                            value: creditsToTransfer,
                            onChange: (e) => setCreditsToTransfer(e),
                        }}
                        meta={{
                            error: creditsToTransfer > credits ? "You don't have enough credits" : null,
                            invalid: creditsToTransfer > credits,
                        }}
                    />
                </div>
                <div className={classes.buttonsContainer}>
                    <Button type="red" onClick={handleClose} disabled={isTransferring}>
                        Cancel
                    </Button>
                    <Button
                        disabled={creditsToTransfer > credits || isTransferring || creditsToTransfer <= 0}
                        onClick={handleTransfer}
                    >
                        {isTransferring ? "Transferring..." : "Transfer"}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default TransferCreditsModal;
