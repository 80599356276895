import React from "react";
import sharedClasses from "../style.module.scss";
import DrawingIcon from "assets/images/icons/JSX/Drawing";
import Tooltip from "components/Tooltip";
import NameInput from "./NameInput";
import { isEqual } from "lodash";

const ComponentHeading = React.memo(({
    nameField,
    type,
    componentId,
    isDrawingComponent,
    icon: Icon,
    isSubComponent
}) => {
    return (
        <>
            <span className={`${sharedClasses.heading} ${isSubComponent && "subComponent"}`}>
                <img src={Icon} alt="Wall icon" />
                <NameInput nameField={nameField} type={type} />
                {isDrawingComponent && (
                    <span
                        className={sharedClasses.drawingIcon}
                        data-tip="<span style='display:block;width:100%;text-align:center;'>This component was created using the <strong>Drawing Capture</strong> tool.</span>"
                        data-html
                        data-for={`headingTip_${componentId}`}
                    >
                        <DrawingIcon />
                    </span>
                )}
            </span>
            <Tooltip id={`headingTip_${componentId}`} />
        </>
    );
}, isEqual);

export default ComponentHeading;
