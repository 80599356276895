import { connect } from "react-redux";
import TopBar from "./";

const mapStateToProps = ({
    user: {
        name = "",
        modelDir: { models = {} } = {},
        organization: { memberOrgIds: { primaryOrg = "" } = {}, details: organizations = {} } = {},
    },
    model: { modelId },
}) => {
    const { [modelId]: currentModel = {} } = models;
    const { name: modelName } = currentModel;

    return {
        name,
        modelName,
        organizations,
        primaryOrg,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
