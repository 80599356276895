import React, { useState } from "react";
import { storageRef } from "_firebase";
import { connect } from "react-redux";

import userActions from "store/users/actions";

import UploadLogo from "./UploadLogo";
import NoLogoUploaded from "./NoLogoUploaded";

import PencilIcon from "assets/images/icons/white-pencil.svg";

import classes from "./style.module.scss";

const { updateOrganization } = userActions;

const OrgLogo = ({ logoUrl, organizationDetails, orgId, uid, updateOrganization, IS_ADMIN = false, setAlert }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [uploadState, setUploadState] = useState({
        isDraggingOver: false,
        dropError: false,
        progress: 0,
        isUploading: false,
    });

    const handleUpload = (e, isDropdown) => {
        e.preventDefault();

        document.body.style.cursor = "progress";

        setUploadState({ ...uploadState, isDraggingOver: false, dropError: false, isUploading: true, progress: 0 });

        const { files } = isDropdown ? e.dataTransfer : e.target;

        const file = files[0];

        if (!file.type.includes("image")) {
            setUploadState({ ...uploadState, dropError: true });
            return;
        }

        const uploadTask = storageRef.child(`${uid}/organization/${orgId}/${file.name}`).put(file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

                console.log("Upload is " + progress + "% done");
                setUploadState({ ...uploadState, progress, isUploading: true });
            },
            (error) => {
                console.log(error);
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
                    await updateOrganization(orgId, {
                        ...organizationDetails,
                        orgMeta: { ...organizationDetails.orgMeta, logoUrl: downloadURL },
                    });

                    setUploadState({ ...uploadState, isUploading: false });

                    setAlert({
                        open: true,
                        message: "Logo uploaded successfully!",
                        anchorOrigin: { vertical: "top", horizontal: "center" },
                        direction: "down",
                        severity: "success",
                    });

                    setIsHovered(false);
                });
            }
        );
    };

    return logoUrl && !uploadState.isUploading ? (
        <div
            className={classes.logoContainer}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {IS_ADMIN && (
                <div className={`${classes.editLogoContainer} ${isHovered ? classes.showEditLogoContainer : ""}`}>
                    <img src={PencilIcon} alt="Edit Logo" />
                    <input
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            cursor: "pointer",
                        }}
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleUpload(e, false)}
                    />
                </div>
            )}
            <img src={logoUrl} alt="Organization Logo" />
        </div>
    ) : IS_ADMIN ? (
        <UploadLogo
            organizationDetails={organizationDetails}
            orgId={orgId}
            uid={uid}
            setUploadState={setUploadState}
            uploadState={uploadState}
        />
    ) : (
        <NoLogoUploaded />
    );
};

const mapDispatchToProps = (dispatch) => ({
    updateOrganization: (orgId, orgData) => dispatch(updateOrganization(orgId, orgData)),
});

export default connect(null, mapDispatchToProps)(OrgLogo);
