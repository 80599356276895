import React, { useRef, useEffect, useCallback, useMemo, useState } from "react";
import { Field } from "redux-form";
import { isEqual } from "lodash";
import { DragOverlay } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { getOptions } from "utils/fields";
import { getValidation, required, maxLength } from "utils/fieldValidation";

import InputRow from "components/Input/Row";
import Select from "components/Input/Select";
import Compass from "components/Compass";
import Accordion from "components/Accordion";
import Measurements from "./Measurements/container";
import Construction from "./Construction/container";
import Actions from "../Actions/container";
import WallStats from "./WallStats/container";
import Checkbox from "components/Input/Checkbox";
import Window from "../Window/container";
import Door from "../Door/container";
import FloorHeader from "../FloorHeader/container";
import ComponentHeading from "../ComponentHeading";
import Droppable from "components/DragAndDrop/Droppable";
import ComponentType from "../ComponentType";

import WallIcon from "assets/images/components/Wall.svg";

import sharedClasses from "../style.module.scss";
import classes from "./style.module.scss";

const charLim32 = maxLength(32);

const orientationValidation = getValidation("wallDirection");
const wallLabelValidation = [required, charLim32, ...getValidation("wallLabel")];

const MemoizedActions = ({
    nameField,
    label,
    type,
    parentPath,
    componentId,
    dragHandleProps,
    newComponentDisplay,
    drawingLines,
    change,
    nameDialogOpen,
    toggleNameDialog,
    listeners,
}) =>
    useMemo(() => {
        return (
            <Actions
                nameField={nameField}
                label={label}
                type={type}
                parentPath={parentPath}
                componentId={componentId}
                dragHandleProps={dragHandleProps}
                newComponentDisplay={newComponentDisplay}
                drawingLines={drawingLines}
                change={change}
                nameDialogOpen={nameDialogOpen}
                toggleNameDialog={toggleNameDialog}
                listeners={listeners}
            />
        );
    }, [nameField, label, type, parentPath, componentId, dragHandleProps, newComponentDisplay, drawingLines, change]);

const Wall = React.memo(
    ({
        facingId,
        accessor,
        label = "",
        change,
        componentId,
        isNew = false,
        isRecentDuplicate = false,
        parentPath = "",
        dragHandleProps,
        listeners,
        allowedCategories,
        dragActive,
        isDrawingComponent,
        clearNewComponent,
        drawingLines,
        contentOpen,
        activeId,
        isDragging,
        coords,
        position,
        subCompObj,
        dropPosition,
        mapSubComponents,
        componentsObject,
    }) => {
        const [nameDialogOpen, toggleNameDialog] = useState(false);
        const [items, setItems] = useState([]);

        const ref = useRef(null);

        // const lastComponentDisplayOrder = items.reduce(
        //     ({ displayOrder: prevDisplay }, { displayOrder: currentDisplay }) =>
        //         prevDisplay > currentDisplay ? prevDisplay : currentDisplay,
        //     0
        // );

        useEffect(() => {
            if (!isDragging) setItems(mapSubComponents);
        }, [isDragging, mapSubComponents]);

        useEffect(() => {
            if (isNew && !isRecentDuplicate) {
                ref.current.scrollIntoView();
            }
        }, [isNew, isRecentDuplicate]);

        const activeSubIndex = activeId ? items.findIndex(({ id }) => id === activeId) : -1;

        return (
            <div
                className={`${classes.wall}  ${
                    dropPosition === "after"
                        ? sharedClasses.activeAfter
                        : dropPosition === "before"
                        ? sharedClasses.activeBefore
                        : ""
                } `}
            >
                <span ref={ref} className={sharedClasses.componentRef}></span>
                <Accordion
                    className={sharedClasses.accordion}
                    heading={
                        <ComponentHeading
                            nameField={{
                                name: `${accessor}.label`,
                                validate: wallLabelValidation,
                                current: label,
                                change,
                            }}
                            type="wall"
                            componentId={componentId}
                            isDrawingComponent={isDrawingComponent}
                            icon={WallIcon}
                        />
                    }
                    large
                    initOpenState={isNew && !isRecentDuplicate}
                    isNew={isNew}
                    toggleNew={useCallback(() => clearNewComponent(componentId), [componentId, clearNewComponent])}
                    isDragging={isDragging}
                    dragActive={dragActive}
                    id={componentId}
                    hoverActions={
                        <MemoizedActions
                            nameField={{
                                name: `${accessor}.label`,
                                validate: wallLabelValidation,
                                current: label,
                                change,
                            }}
                            label="Wall"
                            type="wall"
                            parentPath={parentPath}
                            componentId={componentId}
                            dragHandleProps={dragHandleProps}
                            newComponentDisplay={items.length}
                            drawingLines={drawingLines}
                            change={change}
                            nameDialogOpen={nameDialogOpen}
                            toggleNameDialog={toggleNameDialog}
                            listeners={listeners}
                        />
                    }
                    stats={<WallStats accessor={accessor} />}
                    forceOpen={contentOpen}
                >
                    <h4>Measurements</h4>
                    <Measurements accessor={accessor} change={change} isDrawingComponent={isDrawingComponent} />
                    <h4>Construction</h4>
                    <Construction accessor={accessor} change={change} id={componentId} />
                    <InputRow gridTemplate="31.85% auto">
                        <div className={classes.orientation}>
                            <Compass
                                facingId={facingId}
                                disabled={[0, 9].includes(facingId)}
                                rotationOptions={{
                                    0: 0,
                                    1: 180,
                                    2: 135,
                                    3: 90,
                                    4: 45,
                                    5: 0,
                                    6: -45,
                                    7: -90,
                                    8: -135,
                                    9: 0,
                                }}
                            />
                            <Field
                                component={Select}
                                name={`${accessor}.facingDirection`}
                                options={getOptions({ fieldKey: "wallDirection" })}
                                label="Facing Direction"
                                placeholder="Choose Facing Direction"
                                validate={orientationValidation}
                            />
                        </div>
                        <Field
                            className={classes.adjacentCheckbox}
                            component={Checkbox}
                            name={`${accessor}.adjacentEnclosedSpace`}
                            label="Adjacent to enclosed unconditioned space"
                            large
                            type="checkbox"
                        />
                    </InputRow>
                </Accordion>
                <SortableContext
                    items={items}
                    strategy={verticalListSortingStrategy}
                    id={`wall.${componentId}`}
                    disabled={!isDragging ? false : !allowedCategories.includes("wall")}
                >
                    <div
                        className={`${sharedClasses.subComponents} ${
                            items.length === 0 ? sharedClasses.noSubComponents : ""
                        } ${dragActive ? sharedClasses.dragActive : ""}`}
                    >
                        {items.length === 0 && (
                            <span className={sharedClasses.noSubcomponents}>
                                &nbsp;
                                {allowedCategories.includes("wall") && (
                                    <Droppable id={`wall.${componentId}`}></Droppable>
                                )}
                            </span>
                        )}

                        {items.map(({ id, componentId: subComponentId, componentType }, index) => (
                            <div key={subComponentId} className={classes.draggableComponent}>
                                <ComponentType
                                    key={id}
                                    id={id}
                                    index={index + 1}
                                    activeIndex={activeSubIndex}
                                    activeId={activeId}
                                    componentId={componentId}
                                    draggableId={`wall.${componentId}.subcomponents.${componentType}.${subComponentId}`}
                                    typeDrop={"component"}
                                    isDragging={isDragging}
                                    type={componentType}
                                    change={change}
                                    parentAccessor={accessor}
                                    parentPath={`${parentPath}.wall.${componentId}.subcomponents`}
                                    // isDragging={snapshot.isDragging}
                                    allowedCategories={allowedCategories}
                                    dragActive={dragActive}
                                    clearNewComponent={clearNewComponent}
                                    coords={coords}
                                    position={position}
                                    componentsObject={componentsObject}
                                />
                            </div>
                        ))}
                    </div>
                </SortableContext>
                <DragOverlay>
                    {activeId && activeSubIndex >= 0 ? (
                        <ComponentType
                            id={activeId}
                            activeId={activeId}
                            countSubComponents={items.length}
                            clone={true}
                            label={items[activeSubIndex].componentLabel}
                            type={items[activeSubIndex].componentType}
                            componentId={items[activeSubIndex].componentId}
                            parentAccessor={accessor}
                            parentPath={`${parentPath}.wall.${componentId}.subcomponents`}
                            allowedCategories={[]}
                            destination={{}}
                            isDragging={isDragging}
                            typeDrop={"component"}
                            coords={coords}
                            position={position}
                            componentsObject={componentsObject}
                            change={change}
                        />
                    ) : null}
                </DragOverlay>
            </div>
        );
    },
    isEqual
);

export default Wall;
