import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { isEmpty } from "lodash";

import SectionHeading from "components/SectionHeading";
import Button from "components/Button";
import VerticalTabs from "components/Tabs/VerticalTabs";
import UpgradePackage from "./UpgradePackage";
import PackageNameDialog from "./AddNewPackage";

import { selectSaveUpgradePackageLabels } from "store/upgradeLibrary/selectors";
import { selectUserUid, selectUsersOrganization } from "store/users/selectors";

import { fetchUpgradeLabels } from "store/upgradeLibrary/thunk";
import { actions as userActions } from "store/users";
import { resetUpgradeLibrary } from "store/upgradeLibrary/actions";

import AddIcon from "assets/images/icons/JSX/Add";

import classes from "./styles.module.scss";

const { fetchUserCodeLib } = userActions;

const UpgradeLibrary = ({
    uid,
    upgradeLabels,
    fetchUpgradeLabels,
    history,
    fetchUserCodeLib,
    resetUpgradeLibrary,
    userOrganization = {},
}) => {
    const [searchField, setSearchField] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true);
    const [isOpenNewPackageOpen, setIsOpenNewPackageOpen] = useState(false);
    // const [showOrganizationUpgrades, setShowOrganizationUpgrades] = useState(false);
    const [selectedUpgradesType, setSelectedUpgradesType] = useState("my_lib");

    const [initialPath, initialTab] = window.location.pathname.split("upgrade-library");

    const primaryOrgId = Object.keys(userOrganization?.memberOrgIds || {}).includes("primaryOrg")
        ? userOrganization?.memberOrgIds?.primaryOrg || ""
        : Object.keys(userOrganization?.memberOrgIds || {})?.[0] || "";

    const orgId = !isEmpty(userOrganization) ? primaryOrgId : "";

    // Will be easier to convert into multiple organizations
    const dropdownOptions = !orgId
        ? [
              {
                  label: "My Upgrade Library",
                  value: "my_lib",
              },
          ]
        : [
              {
                  label: "My Upgrade Library",
                  value: "my_lib",
              },
              { label: userOrganization?.memberOrgIds[orgId]?.name, value: orgId },
          ];

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        await fetchUpgradeLabels(uid, orgId);
        await fetchUserCodeLib(uid);
        setIsLoading(false);
    }, [uid]);

    useEffect(() => {
        if (uid) {
            fetchData();
        }

        return () => {
            resetUpgradeLibrary();
        };
    }, []);

    const { upgradeLibMap = [], orgUpgradeLibMap = [] } = upgradeLabels || {};

    const currentUpgradeLabels = upgradeLabels
        ? selectedUpgradesType !== "my_lib"
            ? orgUpgradeLibMap
            : upgradeLibMap
        : [];

    useEffect(() => {
        if (currentUpgradeLabels.length > 0 && initialLoad) {
            history.push(`/upgrade-library/${currentUpgradeLabels[0].id}`);

            setInitialLoad(false);
        }
    }, [currentUpgradeLabels, selectedUpgradesType]);

    const tabs = currentUpgradeLabels
        ? currentUpgradeLabels.map(({ name, id }) => ({
              name,
              url: `/${id}`,
          }))
        : [];

    const filteredTabs = tabs.filter(({ name }) => name.toLowerCase().includes(searchField.toLocaleLowerCase()));

    return (
        <div>
            <SectionHeading
                title="Upgrade Library"
                subtitle="Manage your saved upgrade packages."
                sideContent={
                    <Button
                        icon={AddIcon}
                        className={classes.addButton}
                        // disabled={simulateDisabled}
                        onClick={() => setIsOpenNewPackageOpen(true)}
                    >
                        Add New Package
                    </Button>
                }
                sideContentClasses={classes.addButtonWrapper}
            />
            <VerticalTabs
                // title={showOrganizationUpgrades ? userOrganization.memberOrgIds[orgId]?.name : "My Upgrade Packages"}
                dropdownTitle={true}
                dropdownOptions={dropdownOptions}
                dropdownInput={{
                    value: selectedUpgradesType,
                    onChange: (val) => {
                        setSelectedUpgradesType(val);
                        setInitialLoad(true);
                        history.push(`/upgrade-library`);
                    },
                }}
                className={classes.verticalTabsContainer}
                initialTab={initialTab || "/"}
                tabActive={initialTab || "/"}
                tabs={tabs}
                onChange={(e) => history.push(`/upgrade-library${e}`)}
                inputClasses={classes.inputContainer}
                withSearch={true}
                searchOnChange={(e) => setSearchField(e)}
                searchValue={searchField}
                isLoading={isLoading}
                isNotActiveTabLabel={
                    isEmpty(upgradeLabels) ? "No saved upgrade packages to display" : "Select an upgrade package"
                }
                filteredTabs={filteredTabs}
                hasOrganization={!isEmpty(userOrganization)}
            >
                {tabs.map(({ url }) => (
                    <UpgradePackage
                        key={uid}
                        uid={uid}
                        packageId={url.replace("/", "")}
                        fetchUpgradeLabels={() => fetchUpgradeLabels(uid, orgId)}
                        isLoading={isLoading}
                        history={history}
                        organizationId={orgId}
                        showOrganizationUpgrades={selectedUpgradesType !== "my_lib"}
                    />
                ))}
            </VerticalTabs>
            <PackageNameDialog uid={uid} open={isOpenNewPackageOpen} toggleOpen={setIsOpenNewPackageOpen} />
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    upgradeLabels: selectSaveUpgradePackageLabels,
    uid: selectUserUid,
    userOrganization: selectUsersOrganization,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUpgradeLabels: (uid, orgId) => dispatch(fetchUpgradeLabels(uid, orgId)),
    fetchUserCodeLib: (uid) => dispatch(fetchUserCodeLib(uid)),
    resetUpgradeLibrary: () => dispatch(resetUpgradeLibrary()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeLibrary);
