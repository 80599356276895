import React, { useState, useEffect, useRef, useCallback } from "react";
import { Field } from "redux-form";
import { isEmpty, isEqual } from "lodash";
import moment from "moment";

import DropDown from "components/Dropdown";
import Input from "components/Input";
import Button from "components/Button";
import Tooltip from "components/Tooltip";
import Dialog, { CloseDialog } from "components/Dialog";
import { mixpanel } from "components/Mixpanel";
import WindowTableInputs from "../Window/WindowTableInputs";

import { useOutsideClickHook } from "utils/outsideClick";
import { getComponentIcon, getComponentSubOptions, getComponentName, getComponentTemplate } from "utils/enclosure";
import { capFirstLetter } from "utils/generalUtils/generalUtils";

import Move from "assets/images/icons/JSX/Move";
import Edit from "assets/images/icons/JSX/Edit";
import Duplicate from "assets/images/icons/JSX/Duplicate";
import Delete from "assets/images/icons/JSX/Delete";
import Add from "assets/images/icons/JSX/Add";
import classes from "./style.module.scss";

const buildNewSubCompLabel = (componentType, name, currentLabel = "", subCompCounts = {}) => {
    let baseName = name;
    if (componentType === "floorHeader" && currentLabel !== "") {
        baseName = `${currentLabel} - Header`;
    } else if (componentType === "door" && currentLabel !== "") {
        baseName = `${currentLabel} - Door`;
    }

    if (subCompCounts[componentType] > 0) {
        baseName = `${baseName} (${subCompCounts[componentType]})`;
    }

    return baseName;
};

const facingDirMap = {
    S: 0,
    SE: 1,
    E: 2,
    NE: 3,
    N: 4,
    NW: 5,
    W: 6,
    SW: 7,
};

const getWindowDirection = (dir = "") => {
    const trimmed = dir.trim().toUpperCase();
    if (Object.keys(facingDirMap).includes(trimmed)) {
        return { id: facingDirMap[trimmed] };
    }
    return { id: 0 }; //south
};

export default React.memo(
    ({
        nameField,
        label,
        type,
        modelId,
        componentId,
        deleteComponent,
        parentPath = "",
        currentLabel = "",
        subCompCounts,
        dragHandleProps,
        componentData = {},
        components = {},
        renameComponent,
        newComponentDisplay,
        defaultParams,
        drawingLines = [],
        nameError,
        change,
        nameDialogOpen,
        toggleNameDialog,
        listeners,
        modelUnits,
    }) => {
        const [moving, toggleMoving] = useState(false);
        const [menuOpen, toggleMenuOpen] = useState(false);
        const [deleteDialogOpen, toggleDeleteDialog] = useState(false);
        const [deleting, toggleDeleting] = useState(false);
        const [windowTableDrawerOpen, toggleWindowTableDrawer] = useState(false);

        const nameRef = useRef(null);

        const { name, validate, current, change: nameChange } = nameField;
        const oldName = nameRef.current;
        const {
            category = "",
            subcomponents = {},
            upgradePackages = {},
        } = componentData;

        const ref = useOutsideClickHook(() => toggleMenuOpen(false));

        const handleComponentDuplicate = async ({ withChildren = false }) => {
            const newComponentName = `${current} (Copy)`;
            await dupeComponent({
                componentData,
                modelId,
                type,
                parentPath,
                name: newComponentName,
                origId: componentId,
                withChildren,
            });
            toggleMenuOpen(false);
        };

        const addComponent = useCallback(
            ({ componentType, parentPath = "", name, category, displayOrder = 0 }) => {
                const componentId = `${componentType}-${moment().format("YYYYMMDDHHmmssSS")}`;
                const { [capFirstLetter(componentType)]: compDefaults = {} } = defaultParams; //Uncap to handle windows and floorHeaders

                const componentData = {
                    ...getComponentTemplate(componentType),
                    ...compDefaults,
                    label: buildNewSubCompLabel(componentType, name, currentLabel, subCompCounts) || name,
                    category,
                    displayOrder,
                };

                mixpanel.track("Add Component", {
                    Type: componentType,
                    Category: category,
                    "Parent Type": type,
                });

                change(`modelData.components${parentPath}.${componentType}.${componentId}`, componentData);
            },
            [change, type, defaultParams, currentLabel, subCompCounts]
        );

        const handleAddWindowFromTable = useCallback(
            ({ windowTableData = [], parentPath = "", category }) => {
                const windowTemplate = getComponentTemplate("window");
                windowTableData.forEach((window, ind) => {
                    const { title: { value = "Window" } = {} } = window;

                    const [label = "Window", dir = ""] = value.split("_");

                    const componentId = `window-${moment().format("YYYYMMDDHHmmssSS")}${ind}`;
                    const { Window: compDefaults = {} } = defaultParams;

                    const componentData = {
                        ...windowTemplate,
                        ...compDefaults,
                        label,
                        category,
                        displayOrder: ind + subCompCounts,
                        numIdentical: window.qty.value,
                        facingDirection: getWindowDirection(dir),
                        measurements: {
                            ...windowTemplate.measurements,
                            height: window.height.value,
                            width: window.width.value,
                            headerHeight: window.ovHeight.value,
                            overhangWidth: window.ovWidth.value,
                        },
                    };

                    change(`modelData.components${parentPath}.window.${componentId}`, componentData);
                });

                mixpanel.track("Add Window Table H2k", {
                    Type: "window",
                    Category: category,
                    "Parent Type": type,
                });
            },
            [change, type, defaultParams, subCompCounts]
        );

        const subComponentOptions = getComponentSubOptions(type);
        const subComponentDropdown = subComponentOptions
            ? subComponentOptions.map((option, index) => ({
                  label: (
                      <span>
                          <Add /> Add {getComponentName(option)}
                      </span>
                  ),
                  onClick: async () => {
                      await addComponent({
                          modelId,
                          componentType: option,
                          parentPath: `${parentPath}.${type}.${componentId}.subcomponents`,
                          name: `New ${getComponentName(option)}`,
                          category,
                          displayOrder: newComponentDisplay,
                      });
                      toggleMenuOpen(false);
                  },
                  className: index === 0 ? classes.componentTop : "",
              }))
            : [];

        const windowTableOption = subComponentOptions
            ? subComponentOptions.includes("window")
                ? [
                      {
                          label: (
                              <span>
                                  <Add /> Window Table
                              </span>
                          ),
                          className: classes.componentTop,
                          onClick: () => toggleWindowTableDrawer(!windowTableDrawerOpen),
                      },
                  ]
                : []
            : [];

        const duplicateOptions = [
            {
                label: (
                    <span>
                        <Duplicate /> Duplicate
                    </span>
                ),
                onClick: () => {
                    handleComponentDuplicate({ withChildren: false });
                },
                tipId: `${componentId}-tip`,
            },
        ];

        // Duplicate component with children
        const hasChildren = Object.values(subCompCounts).reduce((a, b) => a + b) > 0;

        if (hasChildren) {
            duplicateOptions.push({
                label: (
                    <span>
                        <Duplicate /> Duplicate All
                    </span>
                ),
                onClick: () => {
                    handleComponentDuplicate({ withChildren: true });
                },
                tipId: `${componentId}-tip`,
            });
        }

        useEffect(() => {
            nameRef.current = current;
        }, [nameDialogOpen]);

        // Returns next duplicate number and object with duplicated components of a certain type, e.g. window, door, etc.
        const dupeSubComponentType = ({ subComponents = {}, subType = "", initCount = 1 }) => {
            // Current number for tracking subcomponent Ids within this component type
            let count = initCount;

            // List of duplicate components and their new ids
            let duplicates = Object.entries(subComponents).map(([oldId, subComponent]) => {
                const subComponentId = `${subType}-${moment().format("YYYYMMDDHHmmssSS")}-${count++}`;
                const subComponentData = {
                    ...subComponent,
                    subcomponents: dupeSubComponents(subComponent.subcomponents, count),
                    label: `${subComponent.label} (Copy)`,
                };

                // Update next duplicate number if subcomponent has more subcomponents
                if (!isEmpty(subComponent.subcomponents)) {
                    count = count + 1 + Object.values(subComponent.subcomponents || {}).length;
                }
                // Return duplicated component's ID and value
                return [subComponentId, subComponentData];
            });

            return {
                nextNumber: count,
                duplicatesByType: Object.fromEntries(duplicates),
            };
        };

        // Return object with duplicated subcomponents of all types, tracks count for unique ids
        const dupeSubComponents = (subComponentsData = {}, initCount = 1) => {
            // Current number for tracking subcomponent Ids across component types
            let count = initCount;
            // List of component types and their duplicate components
            const duplicates = Object.entries(subComponentsData).map(([subType, subComponents]) => {
                const { nextNumber, duplicatesByType } = dupeSubComponentType({
                    subComponents,
                    subType,
                    initCount: count,
                });
                count = nextNumber;
                return [subType, duplicatesByType];
            });
            return Object.fromEntries(duplicates);
        };

        const dupeComponent = useCallback(
            ({ componentData: component = {}, type, parentPath = "", name, origId, withChildren = false }) => {
                const componentId = `${type}-${moment().format("YYYYMMDDHHmmssSS")}`;
                const componentData = {
                    ...component,
                    subcomponents: withChildren ? dupeSubComponents(component.subcomponents) : {},
                    label: name,
                };

                mixpanel.track("Duplicate Component", {
                    Type: type,
                    Category: category,
                });

                change(`modelData.components${parentPath}.${type}.${componentId}`, componentData);
            },
            [change]
        );

        return (
            //! This action is the actions component from each component added by the user
            //! which holds the options to add subcomponents such as doors and whatnot.
            <div className={classes.actions} ref={ref}>
                <div className={classes.menu}>
                    <div className={classes.dots} onClick={() => toggleMenuOpen(!menuOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <DropDown
                        className={classes.dropDown}
                        open={menuOpen}
                        toggleOpen={toggleMenuOpen}
                        options={[
                            {
                                label: (
                                    <span>
                                        <Edit /> Rename
                                    </span>
                                ),
                                onClick: () => toggleNameDialog(true),
                            },
                            ...duplicateOptions,
                            ...subComponentDropdown,
                            ...windowTableOption,
                            {
                                label: (
                                    <span>
                                        <Delete /> Delete
                                    </span>
                                ),
                                className: classes.delete,
                                onClick: () => toggleDeleteDialog(true),
                            },
                        ]}
                    />
                </div>
                <div
                    className={`${classes.move} `}
                    {...dragHandleProps}

                    // {...listeners}
                >
                    <div data-for={`dragTip-${componentId}`} data-tip="Move component">
                        <Move />
                    </div>
                </div>
                <Tooltip id={`dragTip-${componentId}`} delay />
                <Dialog open={nameDialogOpen} actionkey={"Enter"} closeActions={() => toggleNameDialog(false)}>
                    <CloseDialog
                        closeActions={() => {
                            nameChange(name, oldName);
                            return toggleNameDialog(false);
                        }}
                    />
                    <div className={classes.dialog}>
                        {getComponentIcon(type) && <img src={getComponentIcon(type)} alt={`${label} icon`} />}
                        <h2>Edit {label} Name</h2>
                        <Field
                            component={Input}
                            type="text"
                            name={name}
                            label={`${label} Name`}
                            placeholder={`Name your ${type}`}
                            validate={validate}
                            hideLabel
                        />
                        <div className={classes.footer}>
                            <Button
                                type="hollow"
                                onClick={() => {
                                    nameChange(name, oldName);
                                    return toggleNameDialog(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <span data-tip="Name field is required" data-for="dialogTip">
                                <Button
                                    onClick={async () => {
                                        await renameComponent({
                                            field: name,
                                            value: current,
                                            change,
                                        });
                                        toggleNameDialog(false);
                                    }}
                                    disabled={current === "" || !!nameError}
                                >
                                    Done
                                </Button>
                            </span>
                        </div>
                        <Tooltip id="dialogTip" hide={current !== ""} />
                    </div>
                </Dialog>
                <Dialog open={deleteDialogOpen}>
                    <CloseDialog closeActions={() => toggleDeleteDialog(false)} />
                    <div className={classes.dialog}>
                        {getComponentIcon(type) && <img src={getComponentIcon(type)} alt={`${label} icon`} />}
                        <h3>
                            Are you sure you want to delete <strong>{current}</strong>?
                        </h3>
                        <div className={classes.footer}>
                            <Button type="hollow" onClick={() => toggleDeleteDialog(false)}>
                                Cancel
                            </Button>
                            <Button
                                onClick={async () => {
                                    toggleDeleting(true);
                                    await deleteComponent({
                                        change,
                                        modelId,
                                        type,
                                        parentPath,
                                        componentId,
                                        components: JSON.parse(JSON.stringify(components)),
                                        upgradePackages,
                                    });
                                    toggleDeleting(false);
                                    toggleDeleteDialog(false);
                                }}
                                type="red"
                                disabled={deleting}
                            >
                                {deleting ? "Deleting..." : "Delete"}
                            </Button>
                        </div>
                    </div>
                </Dialog>
                <WindowTableInputs
                    windowDrawerOpen={windowTableDrawerOpen}
                    handleWindowDrawerClose={() => toggleWindowTableDrawer(false)}
                    parentLabel={currentLabel}
                    handleWindowTableImport={handleAddWindowFromTable}
                    parentPath={`${parentPath}.${type}.${componentId}.subcomponents`}
                    category={category}
                    modelUnits={modelUnits}
                />
            </div>
        );
    },
    isEqual
);
