import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
    selectUsersOrganization,
    selectUserOrgLoading,
    selectUserUid,
    selectUserCreditSummary,
} from "store/users/selectors";
import userActions from "store/users/actions";

import SectionHeading from "components/SectionHeading";
import Loading from "components/Loading";
import Select from "components/Input/Select";
import OrgLogo from "./OrgLogo";
import MembersList from "./MembersList";
import RenameOrganizationModal from "./RenameOrganizationModal";
import SnackbarAlert from "components/Alert";

import PencilIcon from "assets/images/icons/pencil.svg";

import classes from "./style.module.scss";

const { fetchUserOrganization, updateUserPrimaryOrg, updateUser } = userActions;

const Organization = ({
    organization,
    orgLoading,
    uid,
    fetchUserOrganization,
    updateUserPrimaryOrg,
    creditSummary: { snapParametric = 0, total: totalCredits = 0 } = {},
    updateUser,
}) => {
    const history = useHistory();

    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [alert, setAlert] = useState({
        open: false,
        message: "",
        anchorOrigin: { vertical: "top", horizontal: "center" },
        direction: "down",
    });
    const [isChangingPrimaryOrg, setIsChangingPrimaryOrg] = useState(false);

    const { orgId = "" } = useParams();

    const { details = {}, memberOrgIds = {} } = organization;
    const {
        memberIds = [],
        adminIds = [],
        members = {},
        name = "",
        orgMeta: { orgType = "", logoUrl = "" } = {},
    } = details[orgId] || {};

    const IS_ADMIN = adminIds.includes(uid);
    // const IS_ADMIN = true;

    useEffect(() => {
        if (!orgId && memberOrgIds.primaryOrg) {
            history.push(`/user/organization/${memberOrgIds.primaryOrg}`);
        }

        if (uid && orgId) {
            fetchUserOrganization(uid, orgId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid, fetchUserOrganization, orgId]);

    return (
        <>
            <SectionHeading title="Organization Settings" subtitle="Manage your organization" />
            <div>
                {orgLoading || isChangingPrimaryOrg ? (
                    <Loading />
                ) : (
                    <div className={classes.orgContainer}>
                        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                            <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                <Select
                                    className={classes.orgSelector}
                                    label={"Current Organization"}
                                    options={Object.entries(memberOrgIds)
                                        .filter(([key, _]) => key !== "primaryOrg")
                                        .map(([key, { name }]) => ({
                                            label: name,
                                            value: key,
                                        }))}
                                    input={{
                                        value: orgId,
                                        onChange: async (newOrgId) => {
                                            if (isChangingPrimaryOrg) return;

                                            setIsChangingPrimaryOrg(true);

                                            document.body.style.cursor = "wait";

                                            await updateUserPrimaryOrg(uid, newOrgId);

                                            history.push(`/user/organization/${newOrgId}`);

                                            document.body.style.cursor = "default";

                                            setIsChangingPrimaryOrg(false);
                                        },
                                    }}
                                />
                                {IS_ADMIN && (
                                    <img
                                        style={{ cursor: "pointer", marginTop: "19px" }}
                                        src={PencilIcon}
                                        alt="Edit"
                                        onClick={() => setIsRenameModalOpen(true)}
                                    />
                                )}
                            </div>
                            <OrgLogo
                                logoUrl={logoUrl}
                                organizationDetails={details[orgId]}
                                orgId={orgId}
                                uid={uid}
                                IS_ADMIN={IS_ADMIN}
                                setAlert={setAlert}
                            />
                        </div>
                        <MembersList
                            membersList={members}
                            adminsList={adminIds}
                            memberIds={memberIds}
                            uid={uid}
                            orgId={orgId}
                            IS_ADMIN={IS_ADMIN}
                            fetchUserOrganization={() => fetchUserOrganization(uid, orgId)}
                            setAlert={setAlert}
                            isUsingParametricCredits={snapParametric > 0}
                            parametricCredits={snapParametric}
                            totalCredits={totalCredits}
                            updateUser={updateUser}
                        />
                    </div>
                )}
            </div>
            {IS_ADMIN && (
                <RenameOrganizationModal
                    open={isRenameModalOpen}
                    onClose={() => setIsRenameModalOpen(false)}
                    uid={uid}
                    orgId={orgId}
                    oldName={name}
                    fetchUserOrganization={() => fetchUserOrganization(uid, orgId)}
                    setAlert={setAlert}
                />
            )}
            <SnackbarAlert
                top={"90%"}
                autoHideDuration={6000}
                onClose={() =>
                    setAlert({
                        open: false,
                        message: "",
                        anchorOrigin: { vertical: "top", horizontal: "center" },
                        direction: "down",
                    })
                }
                onExited={() => {}}
                {...alert}
            />
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    fetchUserOrganization: (uid, orgId) => dispatch(fetchUserOrganization(uid, orgId)),
    updateUserPrimaryOrg: (uid, orgId) => dispatch(updateUserPrimaryOrg(uid, orgId)),
    updateUser: (data) => dispatch(updateUser(data)),
});

const mapStateToProps = createStructuredSelector({
    organization: selectUsersOrganization,
    orgLoading: selectUserOrgLoading,
    uid: selectUserUid,
    creditSummary: selectUserCreditSummary,
});

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
