import React, { useEffect, useState, Suspense, lazy } from "react";
import { useParams, Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { isEqual } from "lodash";

import {
    selectParametricAnalysisData,
    selectIsParametricAnalysisLoading,
    selectFetchParametricAnalysisError,
    selectParametricAnalysisToCompare,
    selectIsSavingEcmInputMap,
} from "store/parametricAnalysis/selectors";

import { selectUserCreditSummary } from "store/users/selectors";
import { clearParametricAnalysis } from "store/parametricAnalysis/actions";
import { fetchParametricAnalysis, saveEcmInputMap, watchParametricUpdates } from "store/parametricAnalysis/thunk";

import Loading from "components/Loading";
import SectionHeading from "components/SectionHeading";
import Button from "components/Button";
import Tabs from "components/Tabs";
import ParametricAnalysisUpgradesPage from "../ParametricAnalysisUpgradesPage";

import ParametricAnalysisModelPage from "../ParametricAnalysisModelPage";

import ModelParametricAnalysisIcon from "assets/images/icons/ModelParametricAnalysisIcon.svg";
import UpgradeIcon from "assets/images/icons/Upgrades.svg";
import ResultsIcon from "assets/images/icons/ParametricAnalysisResultsIcon.svg";
import VoltaSmallWhiteIcon from "assets/images/icons/VoltaSmallWhiteIcon.svg";

import classes from "./styles.module.scss";

const ParametricAnalysisResultsPage = lazy(() => import("features/ParametricAnalysis/ParametricAnalysisResultsPage"));

const ParametricAnalysisPage = ({
    parametricAnalysis,
    parametricAnalysisToCompare,
    isLoading,
    fetchParametricAnalysis,
    error,
    isSavingEcmInputMap,
    saveEcmInputMap,
    watchParametricUpdates,
    creditSummary: { snapParametric: initSnapParametricCredits = 0, total: initTotalCredits = 0 } = {},
}) => {
    // Some users might have been using the app before the credit system update
    const [snapParametricCredits, setSnapParametricCredits] = useState(initSnapParametricCredits || initTotalCredits);
    const { analysisId } = useParams();
    const history = useHistory();

    // eslint-disable-next-line no-unused-vars
    const [_, initialTab] = window.location.pathname.split(analysisId);

    const [tab, changeTab] = useState(initialTab);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        if (!analysisId) return;

        fetchParametricAnalysis(analysisId);
    }, [fetchParametricAnalysis, analysisId]);

    useEffect(() => {
        setIsMounted(true);

        return () => {
            setIsMounted(false);
        };
    }, []);

    useEffect(() => {
        if (!analysisId) return;

        const unsubscribe = watchParametricUpdates(analysisId);

        return () => {
            if (unsubscribe) unsubscribe();

            console.log("unsubscribed from watching parametric updates");
        };
    }, [analysisId, watchParametricUpdates]);

    if (!isLoading && error?.type === "not-found" && isMounted) {
        return <Redirect to="/404-page-not-found" />;
    }

    const {
        parametricDetails = {},
        model: { upgrades = {}, modelData = {}, modelDetails = {}, results: modelResuls = {} } = {},
    } = parametricAnalysis || {};

    const { runHistory = {} } = parametricDetails || {};

    return (
        <div>
            {isLoading || !parametricAnalysis ? (
                <Loading />
            ) : (
                <>
                    <SectionHeading
                        title={parametricDetails.name}
                        subtitle="Run a parametric analysis on a model."
                        sideContent={
                            <>
                                <span className={classes.creditsText}>
                                    Available credits: {snapParametricCredits || 0}
                                </span>
                                <Button
                                    icon={() => <img src={VoltaSmallWhiteIcon} alt="Volta" />}
                                    // className={classes.addButton}
                                    // disabled={simulateDisabled}
                                    // onClick={() => setIsOpenNewPackageOpen(true)}
                                    disabled
                                >
                                    Buy credits
                                </Button>
                                {(tab === "/upgrades" || tab === "/results") && (
                                    <Button
                                        disabled={
                                            isEqual(parametricAnalysis, parametricAnalysisToCompare) ||
                                            isSavingEcmInputMap
                                        }
                                        onClick={async () => {
                                            document.body.style.cursor = "progress";

                                            await saveEcmInputMap(analysisId);

                                            document.body.style.cursor = "default";
                                        }}
                                    >
                                        {isSavingEcmInputMap ? "Saving..." : "Save Changes"}
                                    </Button>
                                )}
                            </>
                        }
                        sideContentClasses={classes.buyCreditsButtonWrapper}
                    />

                    <div className={classes.parametricAnalysisWrapper}>
                        <Tabs
                            initialTab={initialTab || "/"}
                            tabActive={tab || "/"}
                            // tabClassName={classes.tabWrapper}
                            tabNavClassName={classes.tabNavWrapper}
                            tabs={[
                                { name: "Model", url: "/", icon: ModelParametricAnalysisIcon },
                                { name: "Upgrades", url: "/upgrades", icon: UpgradeIcon },
                                { name: "Results", url: "/results", icon: ResultsIcon },
                            ]}
                            onChange={(e) => {
                                history.push(`/parametric-analysis/analysis/${analysisId}${e}`);
                                // mixpanel.track("Main Tab Change", {
                                //     Tab: e,
                                // });

                                return changeTab(e);
                            }}
                            stretchTabs
                        >
                            <ParametricAnalysisModelPage
                                lastModelSync={parametricDetails.lastModelSync}
                                modelDetails={modelDetails}
                                upgrades={upgrades}
                                modelData={modelData}
                                analysisId={analysisId}
                                selectedModelId={parametricDetails.selectedModelId}
                                selectedModelHasResults={parametricDetails.selectedModelHasResults}
                                canResyncModel={Object.keys(runHistory)?.length === 0}
                            />
                            <ParametricAnalysisUpgradesPage
                                selectedModelHasResults={parametricDetails.selectedModelHasResults}
                                modelDetails={modelDetails}
                                parametricDetails={parametricDetails}
                                analysisId={analysisId}
                                changeTab={changeTab}
                                setSnapParametricCredits={setSnapParametricCredits}
                                snapParametricCredits={snapParametricCredits}
                            />
                            <Suspense fallback={<Loading />}>
                                <ParametricAnalysisResultsPage
                                    selectedModelHasResults={parametricDetails.selectedModelHasResults}
                                    parametricDetails={parametricDetails}
                                    modelDetails={modelDetails}
                                    analysisId={analysisId}
                                    modelData={modelData}
                                    modelResuls={modelResuls}
                                />
                            </Suspense>
                        </Tabs>
                    </div>
                </>
            )}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    parametricAnalysis: selectParametricAnalysisData,
    parametricAnalysisToCompare: selectParametricAnalysisToCompare,
    isLoading: selectIsParametricAnalysisLoading,
    error: selectFetchParametricAnalysisError,
    isSavingEcmInputMap: selectIsSavingEcmInputMap,
    creditSummary: selectUserCreditSummary,
});

const mapDispatchToProps = (dispatch) => ({
    fetchParametricAnalysis: (id) => dispatch(fetchParametricAnalysis(id)),
    clearParametricAnalysis: () => dispatch(clearParametricAnalysis()),
    saveEcmInputMap: (id) => dispatch(saveEcmInputMap(id)),
    watchParametricUpdates: (id) => dispatch(watchParametricUpdates(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParametricAnalysisPage);
