import React from "react";

import classes from "./style.module.scss";

const NoLogoUploaded = () => {
    return (
        <div className={classes.noLogoContainer}>
            <p className={classes.noLogoText}>No logo was Uploaded</p>
        </div>
    );
};

export default NoLogoUploaded;
