import React, { useContext, useEffect } from "react";
import "./App.scss";
import "assets/styles/index.scss";
import "assets/styles/print.scss";
import moment from "moment";
import Layout from "hoc/Layout/container";
import { AuthContext } from "features/Auth";
import UserTestingInfo from "features/UserTestingInfo/container";
import { auth } from "_firebase";
import Routes from "components/Routes";
import Loading from "components/Loading";

const App = ({ loading, fetchUserData, lastActivity, loadedUid, fetchUserOrganization, organization }) => {
    const { user } = useContext(AuthContext);
    const { uid, metadata: { lastSignInTime = "" } = {} } = user || {};

    useEffect(() => {
        if (uid) {
            fetchUserData(uid);
        }
    }, [uid, fetchUserData]);

    useEffect(() => {
        if (uid && organization?.memberOrgIds && !organization.details) {
            // console.log("uid/org", uid, organization);
            //only handling a single org at the moment

            const { primaryOrg = null, ...restMemberOrgIds } = organization?.memberOrgIds;

            fetchUserOrganization(uid, primaryOrg || Object.keys(restMemberOrgIds)[0]);
        }
    }, [uid, organization]);

    // useEffect(() => {
    //     const today = new Date();
    //     const loginExpired = moment(lastSignInTime).isBefore(moment(today).subtract(12, "hours"));
    //     const inactive = !lastActivity || moment(lastActivity).isBefore(moment(today).subtract(1, "hour"));

    //     // If we've fetched user and the requirements aren't met, logout
    //     if (loadedUid && !loading && loginExpired && inactive) {
    //         auth.signOut();
    //     }
    // }, [lastSignInTime, lastActivity, loadedUid, loading]);

    if (loading) return <Loading message="I am loading" />;

    return (
        <>
            {loadedUid && <UserTestingInfo />}
            <Layout user={user}>
                <Routes />
            </Layout>
        </>
    );
};

export default App;
