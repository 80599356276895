import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import AirHeatPump from "./";
import { isEmpty } from "lodash";

const mapStateToProps = ({ form }, { accessor, formName = "model" }) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");
    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const { major: h2kMajor, minor: h2kMinor } = modelSelector({ form }, "modelData.h2kVersion") || {};

    const currentPrimarySystem = Object.keys(selector({ form }, `${accessor}.system`) || {})[0];

    const isEnergyStar = selector({ form }, `${accessor}.system.airHeatPump.energyStar`) || false;
    const isColdClimate = selector({ form }, `${accessor}.system.airHeatPump.ccashp`) || false;
    const coldClimateSection = selector({ form }, `${accessor}.system.airHeatPump.coldClimate`) || {};
    const { id: functionId } = selector({ form }, `${accessor}.system.airHeatPump.function`) || {};
    const { id: equipTypeId } = selector({ form }, `${accessor}.system.airHeatPump.equipmentType`) || {};
    const { id: capacityId, value: capacityValue } =
        selector({ form }, `${accessor}.system.airHeatPump.specifications.outputCapacity`) || {};
    const { id: tempCutoffId, value: tempCutoffValue = 0 } =
        selector({ form }, `${accessor}.system.airHeatPump.temperature.cutoffType`) || {};
    const { id: tempRatingId, value: tempRatingValue } =
        selector({ form }, `${accessor}.system.airHeatPump.temperature.ratingType`) || {};
    const { isCop: isHeatingEffCop, value: heatingEffValue } =
        selector({ form }, `${accessor}.system.airHeatPump.specifications.heatingEfficiency`) || {};

    const capacityUnits = selector({ form }, `${accessor}.system.airHeatPump.specifications.outputCapacity_u`);
    const crankcaseHeaterUnits = selector({ form }, `${accessor}.system.airHeatPump.crankcaseHeater_u`);
    const openableWindowAreaUnits = selector(
        { form },
        `${accessor}.system.airHeatPump.specifications.coolingParams.openableWindowArea_u`
    );

    const tempCutoffUnits = selector({ form }, `${accessor}.system.airHeatPump.temperature.cutoffType_u`);
    const tempRatingUnits = selector({ form }, `${accessor}.system.airHeatPump.temperature.ratingType_u`);

    const ashpHighHeatTempUnits = selector(
        { form },
        `${accessor}.system.airHeatPump.performanceCurve.heating.highHeating.temperature_u`
    );
    const ashpHighHeatCapUnits = selector(
        { form },
        `${accessor}.system.airHeatPump.performanceCurve.heating.highHeating.maxCapacity_u`
    );

    const ashpMidHeatTempUnits = selector(
        { form },
        `${accessor}.system.airHeatPump.performanceCurve.heating.midHeating.temperature_u`
    );
    const ashpMidHeatCapUnits = selector(
        { form },
        `${accessor}.system.airHeatPump.performanceCurve.heating.midHeating.maxCapacity_u`
    );

    const ashpLowHeatTempUnits = selector(
        { form },
        `${accessor}.system.airHeatPump.performanceCurve.heating.lowHeating.temperature_u`
    );
    const ashpLowHeatCapUnits = selector(
        { form },
        `${accessor}.system.airHeatPump.performanceCurve.heating.lowHeating.maxCapacity_u`
    );

    const ashpHighCoolTempUnits = selector(
        { form },
        `${accessor}.system.airHeatPump.performanceCurve.heating.highCooling.temperature_u`
    );
    const ashpHighCoolCapUnits = selector(
        { form },
        `${accessor}.system.airHeatPump.performanceCurve.heating.highCooling.maxCapacity_u`
    );

    const ashpLowCoolTempUnits = selector(
        { form },
        `${accessor}.system.airHeatPump.performanceCurve.heating.lowCooling.temperature_u`
    );
    const ashpLowCoolCapUnits = selector(
        { form },
        `${accessor}.system.airHeatPump.performanceCurve.heating.lowCooling.maxCapacity_u`
    );

    return {
        accessor,
        formName,
        currentPrimarySystem,
        functionId,
        equipTypeId,
        capacityId,
        capacityValue,
        capacityUnits,
        crankcaseHeaterUnits,
        openableWindowAreaUnits,
        ashpHighHeatTempUnits,
        ashpMidHeatTempUnits,
        ashpLowHeatTempUnits,
        ashpHighCoolTempUnits,
        ashpLowCoolTempUnits,
        ashpHighHeatCapUnits,
        ashpMidHeatCapUnits,
        ashpLowHeatCapUnits,
        ashpHighCoolCapUnits,
        ashpLowCoolCapUnits,
        tempCutoffId,
        tempCutoffValue,
        tempCutoffUnits,
        tempRatingId,
        tempRatingValue,
        tempRatingUnits,
        modelUnits,
        h2kMinor: h2kMinor || 12,
        heatingEffValue,
        lowHeatingEff: isHeatingEffCop === false && heatingEffValue < 7.1 && isEnergyStar,
        isColdClimate,
        hasColdClimateSection: !isEmpty(coldClimateSection),
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AirHeatPump);
