import React, { useCallback, useState, useEffect } from "react";
import { isEmpty } from "lodash";
import Button from "components/Button";
import MultiSelect from "components/Input/MultiSelect";
import Checkbox from "components/Input/Checkbox";
import Accordion from "components/Accordion";
import ModelShare from "assets/images/icons/JSX/ModelShare";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import classes from "./style.module.scss";

import { getOrgMemberOptions } from "utils/fileSharing";

const FileSharing = ({
    change,
    uid,
    organization,
    fetchUserOrganization,
    modelName = "",
    sharedWith,
    owner,
    modelId,
    shareModelFile,
}) => {
    const [selectedUserList, setSelectedUserList] = useState([]);
    const [sharePermissionAcknowledged, setSharePermissionAcknowledged] = useState(false);
    const [sharingLoading, setSharingLoading] = useState(false);

    const { uid: ownerId } = owner;

    let multiSelectOptions = [
        // {
        //     label: "Test Option",
        //     value: "testValue",
        //     disabled: false,
        // },
    ];

    const { primaryOrg = "", ...restMemberOrgIds } = organization?.memberOrgIds || {};

    useEffect(() => {
        if (uid && organization?.memberOrgIds && !organization.details) {
            // console.log("uid/org", uid, organization);
            //only handling a single org at the moment

            fetchUserOrganization(uid, primaryOrg || Object.keys(restMemberOrgIds)[0]);
        }
    }, [uid, organization]);

    const { memberOrgIds = {}, details = {} } = organization;

    const primaryOrgId = Object.keys(organization?.memberOrgIds || {}).includes("primaryOrg")
        ? organization?.memberOrgIds?.primaryOrg || ""
        : Object.keys(organization?.memberOrgIds || {})?.[0] || "";

    const orgId = !isEmpty(organization) ? primaryOrgId : "";

    const { id: primaryMemberOrgId = "", name: primaryMemberOrgName = "" } = memberOrgIds?.[orgId];

    const { [primaryMemberOrgId]: { members = {} } = {} } = details;

    multiSelectOptions = [
        ...multiSelectOptions,
        ...getOrgMemberOptions(members, {
            labelSuffix: (val) => (val.uid === ownerId ? " (owner)" : sharedWith.includes(val.uid) ? " (shared)" : ""),
            disabledCheck: (val) => val.uid === ownerId,
        }),
    ];

    const handleShareClick = useCallback(async () => {
        setSharingLoading(true);
        await shareModelFile(modelId, uid, primaryMemberOrgId, selectedUserList);
        setSharingLoading(false);
        setSelectedUserList([]);
    }, [selectedUserList, ownerId, primaryMemberOrgId, selectedUserList]);

    return (
        <div className={classes.accordionContainer}>
            <Accordion
                className={sharedClasses.accordion}
                heading={
                    <div className={sharedClasses.accordionIconHeading}>
                        Organizational File Sharing <ModelShare />
                    </div>
                }
                large
            >
                <div>
                    <div className={classes.orgHeader}>
                        <p>
                            Your organization: <strong>{primaryMemberOrgName || "None"}</strong>
                        </p>
                        <p>
                            File name: <strong>{modelName || "None"}</strong>
                        </p>
                    </div>
                    <MultiSelect
                        label={"Select members of your organization that you would like to share this file with:"}
                        options={multiSelectOptions}
                        input={{
                            value: selectedUserList,
                            onChange: (value) => setSelectedUserList(value),
                        }}
                        hasSelectAll={true}
                    />
                    <div className={classes.sharingConfirmationText}>
                        <Checkbox
                            name="permissionAcknowledged"
                            label="I have recieved permission from all relevant parties to share this file and its content with the selected individuals"
                            large
                            input={{
                                value: sharePermissionAcknowledged,
                                onChange: () => setSharePermissionAcknowledged(!sharePermissionAcknowledged),
                            }}
                        />
                    </div>
                    <div className={classes.shareButtonContainer}>
                        <Button
                            className={classes.shareButton}
                            type="default"
                            onClick={handleShareClick}
                            disabled={!sharePermissionAcknowledged || isEmpty(selectedUserList) || sharingLoading}
                        >
                            {sharingLoading ? "Sharing..." : "Share File"}
                        </Button>
                    </div>
                </div>
            </Accordion>
        </div>
    );
};

export default FileSharing;
