import React, { useState } from "react";
import classes from "./styles.module.scss";
import Input from "components/Input";
import { connect } from "react-redux";
import moment from "moment";
import { isEqual } from "lodash";

const NameInput = React.memo(({ nameField = {}, type, renameComponent }) => {
    const { name = "", validate = () => {}, current = "", change: nameChange } = nameField;

    const [nameValue, setNameValue] = useState(current);
    const [metaValue, setMeta] = useState({ invalid: false, error: "" });

    const getInputValidation = (value) => {
        var metaObj = { invalid: false, error: "" };

        validate.forEach((validCheck) => {
            if (validCheck(value)) {
                metaObj = { invalid: true, error: validCheck(value) };
                return;
            }
        });

        return metaObj;
    };

    return (
        <span style={{ width: "100%" }} onClick={(event) => event.stopPropagation()}>
            <Input
                type="text"
                name={name}
                input={{
                    value: nameValue,
                    onChange: (value) => {
                        setNameValue(value);
                        setMeta(getInputValidation(value));
                    },
                }}
                className={classes.nameInput}
                placeholder={`Name your ${type}`}
                meta={metaValue}
                hideLabel
                onBlurAction={async () => {
                    if (!isEqual(current, nameValue) && !metaValue.invalid) {
                        await renameComponent({
                            field: name,
                            value: nameValue,
                            change: nameChange,
                        });
                    }
                }}
            />
        </span>
    );
}, isEqual);

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    renameComponent: async ({ field, value, change }) => {
        //Stripping all characters except alphanumeric, white space, dash, underline, round brackets
        var strippedString = value.replace(/[^\w\s-)(]/gi, "");
        if (!!strippedString) {
            change(`modelData.${field.split("modelData.")[1]}`, strippedString);
        }
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(NameInput);
