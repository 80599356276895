import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    simulationRowHeadings,
    simCodeComplianceHeadings,
    chbaProgramComplianceHeadings,
    simulationF280RowHeadings,
    simulationEnergyUseHeadings,
    simulationHeatlossHeadings,
    simulationFuelHeadings,
    simulationCostHeadings,
    extractResultValues,
    getBarGraphResultsData,
    getBarGraphEnvelopeBreakdownResultsData,
    getOperatingExpensesBarGraphData,
    simulationBaseEnergyBreakdownBaseHeadings,
    simulationEnvelopeEnergyBreakdownHeadings,
    simulationHVACEnergyBreakdownHeadings,
    simulationDHWEnergyBreakdownHeadings,
    simulationUpgradeEnergyBreakdownBaseHeadings,
    calcOperatingCosts,
    simulationDetailRows,
} from "utils/upgrades";
import {
    selectLinkedBaselineResults,
    selectPackageResults,
    selectResults,
    selectResultsLoading,
} from "features/Model/Review/Results/_ducks/selectors";
import { getEmptyDividerCells } from "../UpgradesTable";
import classes from "../UpgradesTable.module.scss";
import { actions as upgradeActions } from "../../_ducks";
import { actions as resultsActions } from "features/Model/Review/Results/_ducks";
import { selectModelId, selectModelSimulationData, selectFuelPrices } from "features/Model/_ducks/selectors";
import moment from "moment";
import classNames from "classnames";
import Button from "components/Button";
import Select from "components/Input/Select";
import { ReactComponent as ResetIcon } from "assets/images/icons/Icon-Reset.svg";
import { isEmpty } from "lodash";
import { modelFormSelector } from "store/form/selectors";
import LoadingDots from "components/LoadingDots";
import RowToggle from "../RowToggle/RowToggle";
import DropDown from "components/Dropdown";
import WorkbookCheck from "assets/images/icons/WorkbookCheck.svg";
import resultsArrow from "assets/images/icons/results-arrow.svg";
import useComponentVisible from "utils/hooks";
import { getNetTotalEnergyConsumption } from "utils/results";

const { getResults, getLinkedResults } = resultsActions;
const { updatePackageOperatingConditions, updatePackageBaseCase } = upgradeActions;

const emptySimulationCell = {
    value: "",
    className: classes.simulationHeaderCell,
};

export const getEmptySimulationCells = ({
    upgrades,
    handleSimulate,
    simulateDisabled = false,
    simulationRunning = false,
    loading,
}) => {
    return {
        base: emptySimulationCell,
        ...upgrades.reduce((obj, { id }, index) => {
            if (index === upgrades.length - 1) return obj;

            return {
                ...obj,
                [id]: emptySimulationCell,
            };
        }, {}),
        [upgrades[upgrades.length - 1]?.id]: {
            content: (
                <button
                    onClick={handleSimulate}
                    className={classNames(`normalize-button ${classes.runSimulationTableButton}`, {
                        [classes.simulateButtonRunning]: simulationRunning,
                    })}
                    disabled={simulateDisabled || loading}
                >
                    <ResetIcon /> {simulationRunning ? "Simulation Running" : "Run Simulation"}
                </button>
            ),
            className: classes.simulationHeaderCell,
        },
    };
};

const OutOfSyncBox = ({ classes, handleSimulate, simulateDisabled, simulationRunning }) => {
    return (
        <span className={classes.outOfSyncBox}>
            <p>
                <strong>Upgrade Package no longer aligns with these results</strong>
            </p>
            <Button
                onClick={handleSimulate}
                disabled={simulateDisabled}
                icon={ResetIcon}
                className={classNames({
                    [classes.simulateButtonRunning]: simulationRunning,
                })}
            >
                {simulationRunning ? "Simulation Running" : "Run Simulation"}
            </Button>
        </span>
    );
};

const buildRow = ({
    id: rowKey,
    value,
    path,
    packageUiSpecs = {},
    upgradePackages = {},
    packageResults = {},
    rowIndex = null,
    handleSimulate = null,
    simulateDisabled = false,
    simulationRunning = false,
    fuelPrices = {},
    hideRows,
    hiddenRows = {},
    modelChange,
}) => {
    return {
        id: rowKey,
        house: {
            content: (
                <>
                    <strong>{value}</strong>{" "}
                    <RowToggle
                        className={classNames(classes.rowToggle, {
                            [classes.hide]: !hiddenRows[rowKey],
                        })}
                        rowId={rowKey}
                        modelChange={modelChange}
                    />
                </>
            ),
            className: `${classes.regularCell} ${classes.freezeCell} ${classes.flexCell}`,
            // className: `${classes.regularCell} ${classes.flexCell}`, //not including freezeCell here fixes the issue of disappearing borders
            verticalAlign: "top",
        },
        rowClassName: hideRows && hiddenRows[rowKey] ? classes.hiddenRow : "",
        base: {
            content: extractResultValues({
                allPackageResults: packageResults,
                targetPackageId: "base",
                rowKey,
                fuelPrices,
                packageUiSpecs,
                isBaseColumn: true,
            }),
            className: `${classes.regularCell} ${classes.baseCell}`,
        },
        ...upgradePackages.reduce((obj, upgradePackage) => {
            const { id: upgradeId, lastEdited } = upgradePackage;
            const { metadata: { completedAt } = {} } = packageResults[upgradeId] || {};
            //out of sync
            const outOfSync = lastEdited && moment(lastEdited).isAfter(moment(completedAt));
            const noResults = !packageResults[upgradeId];
            const firstRowAndOutOfSync = outOfSync && rowIndex === 0;
            const getContent = () => {
                if (firstRowAndOutOfSync && handleSimulate) {
                    return (
                        <OutOfSyncBox
                            classes={classes}
                            handleSimulate={handleSimulate}
                            simulateDisabled={simulateDisabled}
                            simulationRunning={simulationRunning}
                        />
                    );
                }

                return extractResultValues({
                    allPackageResults: packageResults,
                    targetPackageId: upgradeId,
                    rowKey,
                    fuelPrices,
                    upgradePackage,
                    packageUiSpecs,
                });
            };

            return {
                ...obj,
                [upgradeId]: {
                    content: getContent(),
                    className: classNames(classes.regularCell, {
                        [classes.outOfSyncCell]: firstRowAndOutOfSync,
                        [classes.fadeCell]: outOfSync,
                    }),
                },
            };
        }, {}),
    };
};

let opCondCases = [
    {
        value: "soc",
        label: "Standard Operating Conditions",
    },
    // {
    //     value: "ersReference",
    //     upgradesValue: "ersReference",
    //     label: "ERS Reference House",
    // },
    {
        value: "hcv",
        label: "Continous Schedule Ventilation",
    },
    {
        value: "hoc",
        label: "Household Operating Conditions",
    },
    {
        value: "roc",
        label: "Reduced Operating Conditions",
    },
    {
        value: "general",
        label: "General Mode",
    },
    {
        value: "ersReference",
        label: "ERS Reference",
    },
];

const SimulationDetailsSelect = ({ rowKey, isGeneralMode, upgradePackage, packageId, packageOpts = [], dispatch }) => {
    const defaultOpCond = isGeneralMode ? "general" : "soc";
    // if (rowKey === "operatingConditions") {

    if (rowKey === "relativeBaseCase") {
        return (
            <Select
                name="opCond"
                placeholder="Select Base Case"
                options={packageOpts}
                disabled={packageOpts.length <= 1}
                input={{
                    onChange: (value) => {
                        dispatch(updatePackageBaseCase({ packageId: packageId, relativeBaseCase: value }));
                    },
                    value: upgradePackage?.ui?.relativeBaseCase || "base",
                }}
            />
        );
    }

    return (
        <Select
            name="opCond"
            placeholder="Condition"
            options={
                isGeneralMode
                    ? [
                          {
                              value: "general",
                              label: "General Mode",
                          },
                      ]
                    : opCondCases
            }
            disabled={isGeneralMode}
            input={{
                onChange: (value) => {
                    dispatch(updatePackageOperatingConditions({ packageId: packageId, operatingConditions: value }));
                },
                value: isGeneralMode ? defaultOpCond : upgradePackage?.ui?.operatingConditions || defaultOpCond,
            }}
        />
    );
    // }
};

const buildOpCondRow = ({
    id: rowKey,
    value,
    path,
    upgradePackages = {},
    baseUi = {},
    packageResults = {},
    rowIndex = null,
    handleSimulate = null,
    simulateDisabled = false,
    simulationRunning = false,
    fuelPrices = {},
    hideRows,
    hiddenRows = {},
    modelChange,
    dispatch,
}) => {
    const { base: { metadata: { mode = "general" } = {} } = {} } = packageResults;

    const isGeneralMode = mode === "general";

    const { name: baseName = "" } = baseUi || {};

    const packageOpts = [
        {
            value: "base",
            label: baseName || "Existing Home",
        },
        ...upgradePackages.reduce((cache, curr) => [...cache, { value: curr.id, label: curr.name }], []),
    ];

    return {
        id: rowKey,
        house: {
            content: (
                <>
                    <strong>{value}</strong>{" "}
                    <RowToggle
                        className={classNames(classes.rowToggle, {
                            [classes.hide]: !hiddenRows[rowKey],
                        })}
                        rowId={rowKey}
                        modelChange={modelChange}
                    />
                </>
            ),
            className: `${classes.regularCell} ${classes.freezeCell} ${classes.flexCell}`,
            // className: `${classes.regularCell} ${classes.flexCell}`, //not including freezeCell here fixes the issue of disappearing borders
            verticalAlign: "top",
        },
        rowClassName: hideRows && hiddenRows[rowKey] ? classes.hiddenRow : "",
        base: {
            content: (
                <SimulationDetailsSelect
                    rowKey={rowKey}
                    isGeneralMode={isGeneralMode}
                    upgradePackage={baseUi}
                    packageId="base"
                    dispatch={dispatch}
                    packageOpts={packageOpts}
                />
            ),
            className: `${classes.regularCell} ${classes.baseCell}`,
        },
        ...upgradePackages.reduce((obj, upgradePackage) => {
            const { id: upgradeId, lastEdited } = upgradePackage;
            const { metadata: { completedAt } = {} } = packageResults[upgradeId] || {};
            //out of sync
            const outOfSync = lastEdited && moment(lastEdited).isAfter(moment(completedAt));
            const noResults = !packageResults[upgradeId];
            const firstRowAndOutOfSync = outOfSync && rowIndex === 0;
            const getContent = () => {
                if (firstRowAndOutOfSync && handleSimulate) {
                    return <></>;
                }

                return (
                    <SimulationDetailsSelect
                        rowKey={rowKey}
                        isGeneralMode={isGeneralMode}
                        upgradePackage={upgradePackage}
                        packageId={upgradeId}
                        dispatch={dispatch}
                        packageOpts={packageOpts}
                    />
                );
            };

            return {
                ...obj,
                [upgradeId]: {
                    content: getContent(),
                    className: classNames(classes.regularCell, {
                        [classes.outOfSyncCell]: firstRowAndOutOfSync,
                        [classes.fadeCell]: outOfSync,
                    }),
                },
            };
        }, {}),
    };
};

const buildGraphContentHeadings = (headings) => {
    return {
        house: {
            content: headings.map(({ value, color }, ind) => (
                <span className={`${classes.graphRow} ${classes.graphHeading}`} key={ind}>
                    <span style={{ backgroundColor: color }}></span>
                    {value}
                </span>
            )),
            className: `${classes.regularCell} ${classes.freezeCell} ${classes.noPadding}`,
        },
    };
};

const buildBars = (data, maxValue) => {
    //We precalculate max value so that all the graphs in the row have the same scale
    // const maxValue = Math.max(...data.map((o) => o.amount));

    return data.map(({ id, amount, label, color }) => (
        <span key={id} className={`${classes.graphRow} ${classes.graphBar}`}>
            <span
                className={classes.graphBarBar}
                style={{
                    backgroundColor: color,
                    width: `${(amount / maxValue) * 100}%`,
                }}
            ></span>
            <span className={classes.graphLabel}>{label}</span>
        </span>
    ));
};

//Generic bar builder used by all types below
const buildBreakdownBars = (data, maxValue) => {
    return data.map(({ id, amount, label, color }) =>
        amount > 0 ? (
            <span key={id} className={`${classes.graphRow} ${classes.graphBar}`}>
                <span
                    className={classes.graphBarBar}
                    style={{
                        backgroundColor: color,
                        width: `${(amount / maxValue) * 100}%`,
                    }}
                ></span>
                <span className={classes.graphLabel}>{label}</span>
            </span>
        ) : (
            <span key={id} className={`${classes.graphRow} ${classes.graphBar}`}>
                <span className={classes.graphBarBar}></span>
            </span>
        )
    );
};

//This one is used in "All simulation results"
const buildHorizontalBarGraph = (simulationEnergyUseHeadings, upgradePackages, packageResults, packageUiSpecs) => {
    const barData = {
        base: simulationEnergyUseHeadings.map(({ id, color }) => ({
            id,
            color,
            ...getBarGraphResultsData(packageResults?.base, id, "base", packageUiSpecs?.base),
        })),
        ...upgradePackages.reduce((obj, { id: upgradeId }) => {
            return {
                ...obj,
                [upgradeId]: simulationEnergyUseHeadings.map(({ id: headingId, color }) => ({
                    id: headingId,
                    color,
                    ...getBarGraphResultsData(
                        packageResults[upgradeId],
                        headingId,
                        "upgrade",
                        packageUiSpecs?.[upgradeId]
                    ),
                })),
            };
        }, {}),
    };

    //precalculate maxValue so charts have the same scale
    const maxValue = []
        .concat(...Object.values(barData))
        .reduce((max, { amount }) => (parseFloat(amount) > max ? parseFloat(amount) : max), 0);

    return {
        base: {
            content: buildBars(barData.base, maxValue),
            className: `${classes.regularCell} ${classes.baseCell} ${classes.noPadding}`,
        },
        ...upgradePackages.reduce((obj, { id: upgradeId, lastEdited }) => {
            const { metadata: { completedAt } = {} } = packageResults[upgradeId] || {};
            //an imported file will blur if this logic is used because it doesn't have a lastEdited value
            // const outOfSync = !lastEdited || moment(lastEdited).isAfter(moment(completedAt));
            const outOfSync = lastEdited && moment(lastEdited).isAfter(moment(completedAt));

            const noResults = !packageResults[upgradeId];
            // TODO: what happens when there are no results? Does it use base or some defaults?
            //@LAUREN: just no content or all 0 / 0% ?

            return {
                ...obj,
                [upgradeId]: {
                    content: buildBars(barData[upgradeId], maxValue),
                    className: classNames(`${classes.regularCell} ${classes.noPadding}`, {
                        [classes.fadeCell]: outOfSync,
                    }),
                },
            };
        }, {}),
    };
};

//Builds the baseline bar for energy and emissions breakdown
const buildBreakdownBaseHorizontalBarGraph = (headings, upgradePackages, packageResults, measure) => {
    const barData = {
        base: [],
        ...upgradePackages.reduce((obj, { id: upgradeId }) => {
            return {
                ...obj,
                [upgradeId]: headings.map(({ id: headingId, color }) => ({
                    id: headingId,
                    color,
                    ...getBarGraphEnvelopeBreakdownResultsData(packageResults[upgradeId], headingId, measure),
                })),
            };
        }, {}),
    };

    //precalculate maxValue so charts have the same scale
    //Have to calculate differently than for allSimulation results.
    //Only ever showing this in Soc mode
    const maxValue = Object.values(packageResults).reduce((max, resPackage) => {
        const resKeys = Object.keys(resPackage?.resultsData || {}).filter(
            (resKey) => resKey.includes("soc") || ["socBaseCase", "socAllResults"].includes(resKey)
        );

        return Math.max(
            max,
            ...resKeys.map((key) =>
                measure === "annualEmissions"
                    ? resPackage?.resultsData?.[key]?.resultsSummary?.annualEmissions || 0
                    : getNetTotalEnergyConsumption(resPackage?.resultsData?.[key] || {})?.zeroFloorTotal
            )
        );
    }, 0);

    return {
        base: {
            content: buildBars(barData.base, maxValue),
            className: `${classes.regularCell} ${classes.baseCell} ${classes.noPadding}`,
        },
        ...upgradePackages.reduce((obj, { id: upgradeId, lastEdited }) => {
            const { metadata: { completedAt } = {} } = packageResults[upgradeId] || {};
            //an imported file will blur if this logic is used because it doesn't have a lastEdited value
            // const outOfSync = !lastEdited || moment(lastEdited).isAfter(moment(completedAt));
            const outOfSync = lastEdited && moment(lastEdited).isAfter(moment(completedAt));

            const noResults = !packageResults[upgradeId];
            // TODO: what happens when there are no results? Does it use base or some defaults?
            //@LAUREN: just no content or all 0 / 0% ?

            return {
                ...obj,
                [upgradeId]: {
                    content: buildBars(barData[upgradeId], maxValue),
                    className: classNames(`${classes.regularCell} ${classes.noPadding}`, {
                        [classes.fadeCell]: outOfSync,
                    }),
                },
            };
        }, {}),
    };
};

const buildBreakdownUpgradesHorizontalBarGraph = (headings, upgradePackages, packageResults, measure) => {
    const barData = {
        base: [],
        ...upgradePackages.reduce((obj, { id: upgradeId }) => {
            return {
                ...obj,
                [upgradeId]: headings.map(({ id: headingId, color }) => ({
                    id: headingId,
                    color,
                    ...getBarGraphEnvelopeBreakdownResultsData(packageResults[upgradeId], headingId, measure),
                })),
            };
        }, {}),
    };

    //precalculate maxValue so charts have the same scale
    //Only ever showing this in Soc mode
    const maxValue = Object.values(packageResults).reduce((max, resPackage) => {
        const resKeys = Object.keys(resPackage?.resultsData || {}).filter(
            (resKey) => resKey.includes("soc") || ["socBaseCase", "socAllResults"].includes(resKey)
        );

        return Math.max(
            max,
            ...resKeys.map((key) =>
                measure === "annualEmissions"
                    ? resPackage?.resultsData?.[key]?.resultsSummary?.annualEmissions || 0
                    : getNetTotalEnergyConsumption(resPackage?.resultsData?.[key] || {})?.zeroFloorTotal
            )
        );
    }, 0);

    return {
        base: {
            content: buildBreakdownBars(barData.base, maxValue),
            className: `${classes.regularCell} ${classes.baseCell} ${classes.noPadding}`,
        },
        ...upgradePackages.reduce((obj, { id: upgradeId, lastEdited }) => {
            const { metadata: { completedAt } = {} } = packageResults[upgradeId] || {};
            //an imported file will blur if this logic is used because it doesn't have a lastEdited value
            // const outOfSync = !lastEdited || moment(lastEdited).isAfter(moment(completedAt));
            const outOfSync = lastEdited && moment(lastEdited).isAfter(moment(completedAt));

            const noResults = !packageResults[upgradeId];
            // TODO: what happens when there are no results? Does it use base or some defaults?
            //@LAUREN: just no content or all 0 / 0% ?

            return {
                ...obj,
                [upgradeId]: {
                    content: buildBreakdownBars(barData[upgradeId], maxValue),
                    className: classNames(`${classes.regularCell} ${classes.noPadding}`, {
                        [classes.fadeCell]: outOfSync,
                    }),
                },
            };
        }, {}),
    };
};

const buildBreakdownHorizontalBarGraph = (headings, upgradePackages, packageResults, measure) => {
    const barData = {
        base: [],
        ...upgradePackages.reduce((obj, { id: upgradeId }) => {
            return {
                ...obj,
                [upgradeId]: headings.map(({ id: headingId, color }) => ({
                    id: headingId,
                    color,
                    ...getBarGraphEnvelopeBreakdownResultsData(packageResults[upgradeId], headingId, measure),
                })),
            };
        }, {}),
    };

    //precalculate maxValue so charts have the same scale
    //Only ever showing this in Soc mode
    const maxValue = Object.values(packageResults).reduce((max, resPackage) => {
        const resKeys = Object.keys(resPackage?.resultsData || {}).filter(
            (resKey) => resKey.includes("soc") || ["socBaseCase", "socAllResults"].includes(resKey)
        );

        return Math.max(
            max,
            ...resKeys.map((key) =>
                measure === "annualEmissions"
                    ? resPackage?.resultsData?.[key]?.resultsSummary?.annualEmissions || 0
                    : getNetTotalEnergyConsumption(resPackage?.resultsData?.[key] || {})?.zeroFloorTotal
            )
        );
    }, 0);

    return {
        base: {
            content: buildBreakdownBars(barData.base, maxValue),
            className: `${classes.regularCell} ${classes.baseCell} ${classes.noPadding}`,
        },
        ...upgradePackages.reduce((obj, { id: upgradeId, lastEdited }) => {
            const { metadata: { completedAt } = {} } = packageResults[upgradeId] || {};
            //an imported file will blur if this logic is used because it doesn't have a lastEdited value
            // const outOfSync = !lastEdited || moment(lastEdited).isAfter(moment(completedAt));
            const outOfSync = lastEdited && moment(lastEdited).isAfter(moment(completedAt));

            const noResults = !packageResults[upgradeId];
            // TODO: what happens when there are no results? Does it use base or some defaults?
            //@LAUREN: just no content or all 0 / 0% ?
            return {
                ...obj,
                [upgradeId]: {
                    content: buildBreakdownBars(barData[upgradeId], maxValue),
                    className: classNames(`${classes.regularCell} ${classes.noPadding}`, {
                        [classes.fadeCell]: outOfSync,
                    }),
                },
            };
        }, {}),
    };
};

const buildExpensesBreakdownHorizontalBarGraph = (headings, upgradePackages, packageResults, fuelPrices) => {
    const barData = {
        base: [],
        ...upgradePackages.reduce((obj, { id: upgradeId }) => {
            return {
                ...obj,
                [upgradeId]: headings.map(({ id: headingId, color }) => ({
                    id: headingId,
                    color,
                    ...getOperatingExpensesBarGraphData(packageResults[upgradeId], headingId, fuelPrices, "upgrade"),
                })),
            };
        }, {}),
    };

    //precalculate maxValue so charts have the same scale
    const maxValue = Object.values(packageResults).reduce((max, resPackage) => {
        const resKeys = Object.keys(resPackage?.resultsData || {}).filter(
            (resKey) => resKey.includes("soc") || ["socBaseCase", "socAllResults"].includes(resKey)
        );

        return Math.max(
            max,
            ...resKeys.map((key) => calcOperatingCosts(resPackage?.resultsData?.[key] || {}, fuelPrices, "total"))
        );
    }, 0);

    return {
        base: {
            content: buildBreakdownBars(barData.base, maxValue),
            className: `${classes.regularCell} ${classes.baseCell} ${classes.noPadding}`,
        },
        ...upgradePackages.reduce((obj, { id: upgradeId, lastEdited }) => {
            const { metadata: { completedAt } = {} } = packageResults[upgradeId] || {};
            //an imported file will blur if this logic is used because it doesn't have a lastEdited value
            // const outOfSync = !lastEdited || moment(lastEdited).isAfter(moment(completedAt));
            const outOfSync = lastEdited && moment(lastEdited).isAfter(moment(completedAt));

            return {
                ...obj,
                [upgradeId]: {
                    content: buildBreakdownBars(barData[upgradeId], maxValue),
                    className: classNames(`${classes.regularCell} ${classes.noPadding}`, {
                        [classes.fadeCell]: outOfSync,
                    }),
                },
            };
        }, {}),
    };
};

const buildBaseExpensesBreakdownHorizontalBarGraph = (headings, upgradePackages, packageResults, fuelPrices) => {
    const barData = {
        base: [],
        ...upgradePackages.reduce((obj, { id: upgradeId }) => {
            return {
                ...obj,
                [upgradeId]: headings.map(({ id: headingId, color }) => ({
                    id: headingId,
                    color,
                    ...getOperatingExpensesBarGraphData(packageResults[upgradeId], headingId, fuelPrices, "upgrade"),
                })),
            };
        }, {}),
    };

    //precalculate maxValue so charts have the same scale
    const maxValue = Object.values(packageResults).reduce((max, resPackage) => {
        const resKeys = Object.keys(resPackage?.resultsData || {}).filter(
            (resKey) => resKey.includes("soc") || ["socBaseCase", "socAllResults"].includes(resKey)
        );

        return Math.max(
            max,
            ...resKeys.map((key) => calcOperatingCosts(resPackage?.resultsData?.[key] || {}, fuelPrices, "total"))
        );
    }, 0);

    return {
        base: {
            content: buildBars(barData.base, maxValue),
            className: `${classes.regularCell} ${classes.baseCell} ${classes.noPadding}`,
        },
        ...upgradePackages.reduce((obj, { id: upgradeId, lastEdited }) => {
            const { metadata: { completedAt } = {} } = packageResults[upgradeId] || {};
            //an imported file will blur if this logic is used because it doesn't have a lastEdited value
            // const outOfSync = !lastEdited || moment(lastEdited).isAfter(moment(completedAt));
            const outOfSync = lastEdited && moment(lastEdited).isAfter(moment(completedAt));

            return {
                ...obj,
                [upgradeId]: {
                    content: buildBars(barData[upgradeId], maxValue),
                    className: classNames(`${classes.regularCell} ${classes.noPadding}`, {
                        [classes.fadeCell]: outOfSync,
                    }),
                },
            };
        }, {}),
    };
};

const buildAllUpgradesExpensesBreakdownHorizontalBarGraph = (headings, upgradePackages, packageResults, fuelPrices) => {
    const barData = {
        base: [],
        ...upgradePackages.reduce((obj, { id: upgradeId }) => {
            return {
                ...obj,
                [upgradeId]: headings.map(({ id: headingId, color }) => ({
                    id: headingId,
                    color,
                    ...getOperatingExpensesBarGraphData(packageResults[upgradeId], headingId, fuelPrices, "upgrade"),
                })),
            };
        }, {}),
    };

    //precalculate maxValue so charts have the same scale
    const maxValue = Object.values(packageResults).reduce((max, resPackage) => {
        const resKeys = Object.keys(resPackage?.resultsData || {}).filter(
            (resKey) => resKey.includes("soc") || ["socBaseCase", "socAllResults"].includes(resKey)
        );

        return Math.max(
            max,
            ...resKeys.map((key) => calcOperatingCosts(resPackage?.resultsData?.[key] || {}, fuelPrices, "total"))
        );
    }, 0);

    return {
        base: {
            content: buildBreakdownBars(barData.base, maxValue),
            className: `${classes.regularCell} ${classes.baseCell} ${classes.noPadding}`,
        },
        ...upgradePackages.reduce((obj, { id: upgradeId, lastEdited }) => {
            const { metadata: { completedAt } = {} } = packageResults[upgradeId] || {};
            //an imported file will blur if this logic is used because it doesn't have a lastEdited value
            // const outOfSync = !lastEdited || moment(lastEdited).isAfter(moment(completedAt));
            const outOfSync = lastEdited && moment(lastEdited).isAfter(moment(completedAt));

            return {
                ...obj,
                [upgradeId]: {
                    content: buildBreakdownBars(barData[upgradeId], maxValue),
                    className: classNames(`${classes.regularCell} ${classes.noPadding}`, {
                        [classes.fadeCell]: outOfSync,
                    }),
                },
            };
        }, {}),
    };
};

const UpgradeTableSimulations = ({
    upgradePackages,
    baseUi,
    handleSimulate,
    modelChange,
    hideRows = false,
    hiddenRows = {},
    rowFilter = () => {},
    acceptLinkedBaseline,
    simulateDisabled,
    isChbaFile = false,
}) => {
    const dispatch = useDispatch();
    const modelId = useSelector(selectModelId);
    const fuelPrices = useSelector(selectFuelPrices);
    // id, created, name, upgradeSettings, upgrades
    // need new headings to map the rows over
    let packageResults = useSelector(selectPackageResults);
    const linkedBaselineResults = useSelector(selectLinkedBaselineResults);

    if (acceptLinkedBaseline === true && !isEmpty(linkedBaselineResults?.packageResults?.base)) {
        const { base, ...rest } = packageResults;
        packageResults = { base: linkedBaselineResults?.packageResults?.base || {}, ...rest };
    }

    const resultsCasesLength = !isEmpty(packageResults) ? Object.keys(packageResults?.base?.resultsData).length : "";
    const isGeneral = resultsCasesLength > 2 ? false : true;

    const { lastCliRun: { cliId: clidIds = {}, datetime: dateRun = "" } = {}, simulationRunning } =
        useSelector(selectModelSimulationData);
    // const { major: h2kMajor, minor: h2kMinor } = useSelector(modelFormSelector)("modelData.h2kVersion");
    const loading = useSelector(selectResultsLoading);

    // const h2kVersion = `v${h2kMajor}-${h2kMinor}`;
    // const simulateDisabled = ["v11-9", "v11-10"].includes(h2kVersion) || simulationRunning || loading;

    const { base: { metadata: { mode, completedAt = "" } = {} } = {}, ...upgradePackageResults } = packageResults;
    const hasResults = !!mode;
    // const [menuOpen, toggleMenuOpen] = useState(false);
    const [breakdown, setBreakdown] = useState("all");
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    useEffect(() => {
        if (!hasResults && Object.keys(clidIds).length > 0) {
            const runIds = Object.values(clidIds).map(({ runId = "" }) => runId);
            // Get most recent cli results
            dispatch(getResults({ modelId, cliIds: runIds }));
        }
    }, [clidIds, modelId, hasResults]);

    const initialEmptyCells = [
        {
            house: {
                content: !isGeneral ? (
                    <div className={classes.selectBreakdown} ref={ref}>
                        <div className={classes.resultsText} onClick={() => setIsComponentVisible(!isComponentVisible)}>
                            <strong>
                                {breakdown === "all"
                                    ? "ALL SIMULATION RESULTS"
                                    : breakdown === "energy"
                                    ? "ENERGY CONSUMPTION (GJ)"
                                    : breakdown === "emissions"
                                    ? "OP. EMISSIONS (tCO₂/y)"
                                    : breakdown === "expenses"
                                    ? "OP. EXPENSES ($/y)"
                                    : ""}
                            </strong>
                            <img
                                src={resultsArrow}
                                style={{
                                    transition: "all 0.2s ease-in-out",
                                    transform: `rotate(${isComponentVisible ? "180deg" : "0deg"})`,
                                }}
                            />
                        </div>
                        <DropDown
                            className={`${classes.dropDown} ${
                                breakdown === "energy" || breakdown === "expenses" ? classes.dropDownExtraTop : ""
                            } 
                            ${breakdown === "emissions" ? classes.dropDownEmissions : ""}`}
                            open={isComponentVisible}
                            toggleOpen={setIsComponentVisible}
                            options={[
                                {
                                    label: (
                                        <div
                                            className={`${classes.breakdown} ${
                                                breakdown === "all" && classes.selected
                                            }`}
                                        >
                                            <div className={classes.checkmarkDiv}>
                                                <img src={WorkbookCheck} alt="Checkmark" />
                                            </div>
                                            <p>All Simulation Results</p>
                                        </div>
                                    ),
                                    // className: classes.delete,
                                    onClick: () => {
                                        setBreakdown("all");
                                        setIsComponentVisible(false);
                                    },
                                },
                                {
                                    label: (
                                        <div
                                            className={`${classes.breakdown} ${
                                                breakdown === "energy" && classes.selected
                                            }`}
                                        >
                                            <div className={classes.checkmarkDiv}>
                                                <img src={WorkbookCheck} alt="Checkmark" />
                                            </div>
                                            <p>Total Energy Consumption Breakdown(GJ)</p>
                                        </div>
                                    ),
                                    // className: classes.delete,
                                    onClick: () => {
                                        setBreakdown("energy");
                                        setIsComponentVisible(false);
                                    },
                                },
                                {
                                    label: (
                                        <div
                                            className={`${classes.breakdown} ${
                                                breakdown === "emissions" && classes.selected
                                            }`}
                                        >
                                            <div className={classes.checkmarkDiv}>
                                                <img src={WorkbookCheck} alt="Checkmark" />
                                            </div>
                                            <p>Est. Operational GHG Emissions Breakdown(tCO₂/y)</p>
                                        </div>
                                    ),
                                    // className: classes.delete,
                                    onClick: () => {
                                        setBreakdown("emissions");
                                        setIsComponentVisible(false);
                                    },
                                },
                                {
                                    label: (
                                        <div
                                            className={`${classes.breakdown} ${
                                                breakdown === "expenses" && classes.selected
                                            }`}
                                        >
                                            <div className={classes.checkmarkDiv}>
                                                <img src={WorkbookCheck} alt="Checkmark" />
                                            </div>
                                            <p>Est. Annual Operating Expenses Breakdown($/y)</p>
                                        </div>
                                    ),
                                    // className: classes.delete,
                                    onClick: () => {
                                        setBreakdown("expenses");
                                        setIsComponentVisible(false);
                                    },
                                },
                            ]}
                        />
                    </div>
                ) : (
                    <strong>ALL SIMULATION RESULTS</strong>
                ),
                className: `${classes.simulationHeaderCell} ${classes.freezeCell} `,
            },
            ...getEmptySimulationCells({
                upgrades: upgradePackages,
                handleSimulate,
                simulateDisabled,
                loading,
                simulationRunning,
            }),
        },
    ];

    // Fetching simulation data
    if (loading && !simulationRunning) {
        return [
            ...initialEmptyCells,
            {
                house: {
                    content: (
                        <span className={classes.noResultsBox}>
                            <p>
                                <strong>Loading simulation results</strong>
                            </p>
                            <LoadingDots />
                        </span>
                    ),
                    className: `${classes.noResultsCell} ${classes.freezeCell}`,
                },
            },
        ];
    }

    if (simulationRunning) {
        return [
            ...initialEmptyCells,
            {
                house: {
                    content: (
                        <span className={classes.noResultsBox}>
                            <p>
                                <strong>A new simulation is in progress. The results should appear momentarily.</strong>
                            </p>
                            <LoadingDots />
                        </span>
                    ),
                    className: `${classes.noResultsCell} ${classes.freezeCell}`,
                },
            },
        ];
    }

    // No simulation data
    if (isEmpty(packageResults)) {
        return [
            ...initialEmptyCells,
            {
                house: {
                    content: (
                        <span className={classes.noResultsBox}>
                            <p>
                                <strong>
                                    No results to display. Once ready, click Run New Simulation to see your model's
                                    performance (v11.11 onward).
                                </strong>
                            </p>
                            <Button
                                onClick={handleSimulate}
                                icon={ResetIcon}
                                disabled={simulateDisabled}
                                className={classNames({
                                    [classes.simulateButtonRunning]: simulationRunning,
                                })}
                            >
                                {simulationRunning ? "Simulation Running" : "Run Simulation"}
                            </Button>
                        </span>
                    ),
                    className: `${classes.noResultsCell} ${classes.freezeCell}`,
                },
            },
        ];
    }

    const packageUiSpecs = {
        base: baseUi,
        ...upgradePackages.reduce((cache, curr) => ({ ...cache, [curr.id]: { id: curr.id, ui: curr.ui } }), {}),
    };

    // need to change params for buildRow, opCondRow, and buildBarGraphRow

    return breakdown === "all"
        ? [
              ...initialEmptyCells,
              {
                  house: {
                      content: <strong>SIMULATION DETAILS</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              ...simulationDetailRows.filter(rowFilter).map((props, index) => {
                  // add linkedBaseline info here
                  return buildOpCondRow({
                      ...props,
                      upgradePackages,
                      baseUi,
                      packageResults: packageResults,
                      rowIndex: index,
                      handleSimulate,
                      simulateDisabled,
                      simulationRunning,
                      hideRows,
                      hiddenRows,
                      modelChange,
                      dispatch,
                  });
              }),
              {
                  house: {
                      content: <strong>PERFORMANCE METRICS</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              ...simulationRowHeadings.filter(rowFilter).map((props, index) =>
                  // add linkedBaseline info here
                  buildRow({
                      ...props,
                      upgradePackages,
                      packageUiSpecs,
                      packageResults: packageResults,
                      rowIndex: index,
                      handleSimulate,
                      simulateDisabled,
                      simulationRunning,
                      hideRows,
                      hiddenRows,
                      modelChange,
                  })
              ),
              {
                  house: {
                      content: <strong>CSA F280-12</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              ...simulationF280RowHeadings.filter(rowFilter).map((props, index) =>
                  buildRow({
                      ...props,
                      upgradePackages,
                      packageUiSpecs,
                      packageResults: packageResults,
                      // packageResults,
                      handleSimulate,
                      simulateDisabled,
                      simulationRunning,
                      hideRows,
                      hiddenRows,
                      modelChange,
                  })
              ),
              {
                  house: {
                      content: <strong>ENERGY END USE</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              {
                  // add linkedBaseline info here

                  ...buildGraphContentHeadings(simulationEnergyUseHeadings),
                  ...buildHorizontalBarGraph(
                      simulationEnergyUseHeadings,
                      upgradePackages,
                      packageResults,
                      packageUiSpecs
                  ),
              },
              {
                  house: {
                      content: <strong>HEAT LOSS</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              {
                  // add linkedBaseline info here

                  ...buildGraphContentHeadings(simulationHeatlossHeadings),
                  ...buildHorizontalBarGraph(
                      simulationHeatlossHeadings,
                      upgradePackages,
                      packageResults,
                      packageUiSpecs
                  ),
              },
              {
                  house: {
                      content: <strong>NBC COMPLIANCE</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              ...simCodeComplianceHeadings.filter(rowFilter).map((props, index) =>
                  // add linkedBaseline info here
                  buildRow({
                      ...props,
                      upgradePackages,
                      packageUiSpecs,
                      packageResults: packageResults,
                      rowIndex: index,
                      handleSimulate,
                      simulateDisabled,
                      simulationRunning,
                      hideRows,
                      hiddenRows,
                      modelChange,
                  })
              ),
              ...(isChbaFile
                  ? [
                        {
                            house: {
                                content: <strong>CHBA NZ COMPLIANCE</strong>,
                                className: `${classes.dividerCell} ${classes.freezeCell}`,
                            },
                            ...getEmptyDividerCells(upgradePackages),
                        },
                        ...chbaProgramComplianceHeadings.filter(rowFilter).map((props, index) =>
                            // add linkedBaseline info here
                            buildRow({
                                ...props,
                                upgradePackages,
                                packageUiSpecs,
                                packageResults: packageResults,
                                rowIndex: index,
                                handleSimulate,
                                simulateDisabled,
                                simulationRunning,
                                hideRows,
                                hiddenRows,
                                modelChange,
                            })
                        ),
                    ]
                  : []),
              {
                  house: {
                      content: <strong>FUEL CONSUMPTION AND PRODUCTION</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              ...simulationFuelHeadings.filter(rowFilter).map((props) =>
                  // add linkedBaseline info here

                  buildRow({
                      ...props,
                      upgradePackages,
                      packageUiSpecs,
                      packageResults: packageResults,
                      // packageResults,
                      rowIndex: null,
                      hideRows,
                      hiddenRows,
                      modelChange,
                  })
              ),
              {
                  house: {
                      content: <strong>ESTIMATED COSTS</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              ...simulationCostHeadings.filter(rowFilter).map((props) =>
                  // add linkedBaseline info here

                  buildRow({
                      ...props,
                      upgradePackages,
                      packageUiSpecs,
                      packageResults: packageResults,
                      packageResults,
                      rowIndex: null,
                      fuelPrices,
                      hideRows,
                      hiddenRows,
                      modelChange,
                  })
              ),
          ]
        : breakdown === "energy"
        ? [
              ...initialEmptyCells,
              {
                  house: {
                      content: <strong>BASE (SOC)</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              {
                  ...buildGraphContentHeadings(simulationBaseEnergyBreakdownBaseHeadings),
                  ...buildBreakdownBaseHorizontalBarGraph(
                      simulationBaseEnergyBreakdownBaseHeadings,
                      upgradePackages,
                      packageResults,
                      "Consumption"
                  ),
              },
              {
                  house: {
                      content: <strong>ENVELOPE UPGRADES (SOC)</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              {
                  ...buildGraphContentHeadings(simulationEnvelopeEnergyBreakdownHeadings),
                  ...buildBreakdownHorizontalBarGraph(
                      simulationEnvelopeEnergyBreakdownHeadings,
                      upgradePackages,
                      packageResults,
                      "Consumption"
                  ),
              },
              {
                  house: {
                      content: <strong>HVAC UPGRADES (SOC)</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              {
                  ...buildGraphContentHeadings(simulationHVACEnergyBreakdownHeadings),
                  ...buildBreakdownHorizontalBarGraph(
                      simulationHVACEnergyBreakdownHeadings,
                      upgradePackages,
                      packageResults,
                      "Consumption"
                  ),
              },
              {
                  house: {
                      content: <strong>DHW UPGRADES (SOC)</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              {
                  ...buildGraphContentHeadings(simulationDHWEnergyBreakdownHeadings),
                  ...buildBreakdownHorizontalBarGraph(
                      simulationDHWEnergyBreakdownHeadings,
                      upgradePackages,
                      packageResults,
                      "Consumption"
                  ),
              },
              {
                  house: {
                      content: <strong>ALL UPGRADES (SOC)</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              {
                  ...buildGraphContentHeadings(simulationUpgradeEnergyBreakdownBaseHeadings),
                  ...buildBreakdownUpgradesHorizontalBarGraph(
                      simulationUpgradeEnergyBreakdownBaseHeadings,
                      upgradePackages,
                      packageResults,
                      "Consumption"
                  ),
              },
          ]
        : breakdown === "emissions"
        ? [
              ...initialEmptyCells,
              {
                  house: {
                      content: <strong>BASE (SOC)</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              {
                  ...buildGraphContentHeadings(simulationBaseEnergyBreakdownBaseHeadings),
                  ...buildBreakdownBaseHorizontalBarGraph(
                      simulationBaseEnergyBreakdownBaseHeadings,
                      upgradePackages,
                      packageResults,
                      "annualEmissions"
                  ),
              },
              {
                  house: {
                      content: <strong>ENVELOPE UPGRADES (SOC)</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              {
                  ...buildGraphContentHeadings(simulationEnvelopeEnergyBreakdownHeadings),
                  ...buildBreakdownHorizontalBarGraph(
                      simulationEnvelopeEnergyBreakdownHeadings,
                      upgradePackages,
                      packageResults,
                      "annualEmissions"
                  ),
              },
              {
                  house: {
                      content: <strong>HVAC UPGRADES (SOC)</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              {
                  ...buildGraphContentHeadings(simulationHVACEnergyBreakdownHeadings),
                  ...buildBreakdownHorizontalBarGraph(
                      simulationHVACEnergyBreakdownHeadings,
                      upgradePackages,
                      packageResults,
                      "annualEmissions"
                  ),
              },
              {
                  house: {
                      content: <strong>DHW UPGRADES (SOC)</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              {
                  ...buildGraphContentHeadings(simulationDHWEnergyBreakdownHeadings),
                  ...buildBreakdownHorizontalBarGraph(
                      simulationDHWEnergyBreakdownHeadings,
                      upgradePackages,
                      packageResults,
                      "annualEmissions"
                  ),
              },
              {
                  house: {
                      content: <strong>ALL UPGRADES (SOC)</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              {
                  ...buildGraphContentHeadings(simulationUpgradeEnergyBreakdownBaseHeadings),
                  ...buildBreakdownUpgradesHorizontalBarGraph(
                      simulationUpgradeEnergyBreakdownBaseHeadings,
                      upgradePackages,
                      packageResults,
                      "annualEmissions"
                  ),
              },
          ]
        : breakdown === "expenses"
        ? [
              ...initialEmptyCells,
              {
                  house: {
                      content: <strong>BASE (SOC)</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              {
                  ...buildGraphContentHeadings(simulationBaseEnergyBreakdownBaseHeadings),
                  ...buildBaseExpensesBreakdownHorizontalBarGraph(
                      simulationBaseEnergyBreakdownBaseHeadings,
                      upgradePackages,
                      packageResults,
                      fuelPrices
                  ),
              },
              {
                  house: {
                      content: <strong>ENVELOPE UPGRADES (SOC)</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              {
                  ...buildGraphContentHeadings(simulationEnvelopeEnergyBreakdownHeadings),
                  ...buildExpensesBreakdownHorizontalBarGraph(
                      simulationEnvelopeEnergyBreakdownHeadings,
                      upgradePackages,
                      packageResults,
                      fuelPrices
                  ),
              },
              {
                  house: {
                      content: <strong>HVAC UPGRADES (SOC)</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              {
                  ...buildGraphContentHeadings(simulationHVACEnergyBreakdownHeadings),
                  ...buildExpensesBreakdownHorizontalBarGraph(
                      simulationHVACEnergyBreakdownHeadings,
                      upgradePackages,
                      packageResults,
                      fuelPrices
                  ),
              },
              {
                  house: {
                      content: <strong>DHW UPGRADES (SOC)</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              {
                  ...buildGraphContentHeadings(simulationDHWEnergyBreakdownHeadings),
                  ...buildExpensesBreakdownHorizontalBarGraph(
                      simulationDHWEnergyBreakdownHeadings,
                      upgradePackages,
                      packageResults,
                      fuelPrices
                  ),
              },
              {
                  house: {
                      content: <strong>ALL UPGRADES (SOC)</strong>,
                      className: `${classes.dividerCell} ${classes.freezeCell}`,
                  },
                  ...getEmptyDividerCells(upgradePackages),
              },
              {
                  ...buildGraphContentHeadings(simulationUpgradeEnergyBreakdownBaseHeadings),
                  ...buildAllUpgradesExpensesBreakdownHorizontalBarGraph(
                      simulationUpgradeEnergyBreakdownBaseHeadings,
                      upgradePackages,
                      packageResults,
                      fuelPrices
                  ),
              },
          ]
        : [];
};

export default UpgradeTableSimulations;
