export const DEFAULT_ECM_INPUT_MAP = {
    ceilingWithAttic: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    cathedral: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    wall: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    expFloor: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    floorHeader: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    basementWall: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    basementSlab: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    crawlspace: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    slab: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    window: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    skylights: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    door: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    airTightness: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    ventilation: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    spaceHeating: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    secondaryHeating: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    spaceCooling: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    primaryHotWater: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    dwhr: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    generation: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
    baseLoads: {
        None: { label: "No Change", active: true, passed: true, individualSavings: 0, noChange: true, order: 0 },
    },
};

export const UPGRADE_SELECTION_OPTIONS = [
    // { whereToAdd: "ceilingWithAttic", filter: "ceiling"}
    {
        label: "Ceiling With Attic",
        value: { type: "ceilingWithAttic", upgradeType: "ceiling" },
    },
    {
        label: "Cathedral",
        value: { type: "cathedral", upgradeType: "ceiling" },
    },
    {
        label: "Above Grade Walls / Garage wall",
        value: { type: "wall", upgradeType: "wall" },
    },
    {
        label: "Exposed Floors",
        value: { type: "expFloor", upgradeType: "expFloor" },
    },
    {
        label: "Floor Header",
        value: { type: "floorHeader", upgradeType: "floorHeader" },
    },
    {
        label: "Basement Wall",
        value: { type: "basementWall", upgradeType: "basement" },
    },
    {
        label: "Basement Slab",
        value: { type: "basementSlab", upgradeType: "basement" },
    },
    {
        label: "Crawlspace",
        value: { type: "crawlspace", upgradeType: "crawlspace" },
    },
    {
        label: "Slab-on-grade",
        value: { type: "slab", upgradeType: "slab" },
    },
    {
        label: "Window",
        value: { type: "window", upgradeType: "window" },
    },
    {
        label: "Skylights",
        value: { type: "skylights", upgradeType: "window" },
    },
    {
        label: "Door",
        value: { type: "door", upgradeType: "door" },
    },
    {
        label: "Air Tightness",
        value: { type: "airTightness", upgradeType: "airTightness" },
    },
    {
        label: "Ventilation",
        value: { type: "ventilation", upgradeType: "ventilation" },
    },
    {
        label: "Space Heating",
        value: { type: "spaceHeating", upgradeType: "heatingCooling" },
    },
    {
        label: "Secondary Heating",
        value: { type: "secondaryHeating", upgradeType: "heatingCooling" },
    },
    {
        label: "Cooling & Heat Pumps",
        value: { type: "spaceCooling", upgradeType: "heatingCooling" },
    },
    {
        label: "Primary Hot Water",
        value: { type: "primaryHotWater", upgradeType: "domesticHotWater" },
    },
    {
        label: "DWHR",
        value: { type: "dwhr", upgradeType: "domesticHotWater" },
    },
    {
        label: "Generation",
        value: { type: "generation", upgradeType: "generation" },
    },
    // {
    //     label: "Base Loads",
    //     value: { type: "baseLoads", upgradeType: "baseLoads" },
    // },
];

export const PARAMETRIC_TABLE_HEADERS = {
    ceilingWithAttic: "Ceiling With Attic",
    cathedral: "Cathedral",
    wall: "Above Grade Walls / Garage wall",
    expFloor: "Exposed Floors",
    floorHeader: "Floor Header",
    basementWall: "Foundation Wall",
    basementSlab: "Basement Slab",
    crawlspace: "Crawlspace",
    slab: "Slab-on-grade",
    window: "Windows & Sliding Glass Doors",
    skylights: "Skylights",
    door: "Door",
    airTightness: "Air Tightness",
    ventilation: "Ventilation",
    spaceHeating: "Space Heating",
    secondaryHeating: "Secondary Heating",
    spaceCooling: "Cooling & Heat Pumps",
    primaryHotWater: "Primary Hot Water",
    dwhr: "DWHR",
    generation: "Generation",
    // baseLoads: "Base Loads",
};

export const SORTED_UPGRADES_TYPES = [
    "ceilingWithAttic",
    "cathedral",
    "wall",
    "expFloor",
    "floorHeader",
    "basementWall",
    "basementSlab",
    "crawlspace",
    "slab",
    "window",
    "skylights",
    "door",
    "airTightness",
    "ventilation",
    "spaceHeating",
    "secondaryHeating",
    "spaceCooling",
    "primaryHotWater",
    "dwhr",
    "generation",
];

export const DEFAULT_FILTERS = {
    ceilingWithAttic: {
        label: "Ceiling With Attic",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    cathedral: {
        label: "Cathedral",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    wall: {
        label: "Above Grade Walls / Garage wall",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    expFloor: {
        label: "Exposed Floors",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    floorHeader: {
        label: "Floor Header",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    basementWall: {
        label: "Foundation Wall",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    basementSlab: {
        label: "Basement Slab",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    crawlspace: {
        label: "Crawlspace",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    slab: {
        label: "Slab-on-grade",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    window: {
        label: "Windows & Sliding Glass Doors",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    skylights: {
        label: "Skylights",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    door: {
        label: "Doors",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    airTightness: {
        label: "Air Tightness",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    ventilation: {
        label: "Ventilation",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    spaceHeating: {
        label: "Space Heating",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    secondaryHeating: {
        label: "Secondary Heating",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    spaceCooling: {
        label: "Cooling & Heat Pumps",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    primaryHotWater: {
        label: "Primary Hot Water",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    dwhr: {
        label: "DWHR",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    generation: {
        label: "Generation",
        selectedUpgrades: [],
        upgradesOptions: [],
        hasOptions: false,
    },
    // baseLoads: {
    //     label: "Base Loads",
    //     selectedUpgrades: []

    // hasOptions: false, // },
};

export const DEFAULT_LEGEND_OPTIONS = [
    {
        label: "Ceiling With Attic",
        value: "ceilingWithAttic",
    },
    {
        label: "Cathedral",
        value: "cathedral",
    },
    {
        label: "Above Grade Walls",
        value: "wall",
    },
    {
        label: "Exposed Floors",
        value: "expFloor",
    },
    {
        label: "Floor Header",
        value: "floorHeader",
    },
    {
        label: "Basement Wall",
        value: "basementWall",
    },
    {
        label: "Basement Slab",
        value: "basementSlab",
    },
    {
        label: "Crawlspace",
        value: "crawlspace",
    },
    {
        label: "Slab",
        value: "slab",
    },
    {
        label: "Windows & Glass Doors",
        value: "window",
    },
    {
        label: "Skylights",
        value: "skylights",
    },
    {
        label: "Door",
        value: "door",
    },
    {
        label: "Air Tightness",
        value: "airTightness",
    },
    {
        label: "Ventilation",
        value: "ventilation",
    },
    {
        label: "Space Heating",
        value: "spaceHeating",
    },
    {
        label: "Secondary Heating",
        value: "secondaryHeating",
    },
    {
        label: "Cooling & Heat Pumps",
        value: "spaceCooling",
    },
    {
        label: "Primary Hot Water",
        value: "primaryHotWater",
    },
    {
        label: "DWHR",
        value: "dwhr",
    },

    // {
    //     label: "Generation",
    //     value: "generation",
    // },
];

export const COLOURS_FOR_LEGEND = [
    "#0049C6",
    "#F58020",
    "#62BCF8",

    "#C61717",
    "#18C1AD",
    "#AC0D34",
    "#1B97BA",
    "#FFB22D",
    "#0B91EA",
    "#0EB0A9",
];

export const PARAMETRIC_RESULTS_TABLE = [
    { label: "Energy Savings (%)", value: "percentSavings", idForBase: "energyConsumptionReduction" },
    { label: "Annual Energy Consumption (GJ)", value: "aecGJ", idForBase: "totalEnergyConsumption" },
    { label: "Annual Energy Production (GJ)", value: "pvGJ", idForBase: "pvProductionGj" },
    { label: "Space Heating (GJ)", value: "spaceHeatingGJ", idForBase: "primaryHeating" },
    { label: "Hot Water (GJ)", value: "hotWaterGJ", idForBase: "primaryDHW" },
    { label: "Baseloads (GJ)", value: "baseloadGJ", idForBase: "conditioningDhwImprovement" },
    { label: "Space Cooling (GJ)", value: "spaceCoolingGJ", idForBase: "" },
    { label: "Ventilation (GJ)", value: "ventilationGJ", idForBase: "ventilation" },
    { label: "Electricity (GJ)", value: "elecGJ", idForBase: "electricityGj" },
    { label: "Natural Gas (GJ)", value: "ngGJ", idForBase: "naturalGasGj" },
    { label: "Propane (GJ)", value: "propaneGJ", idForBase: "propaneGj" },
    { label: "Oil (GJ)", value: "oilGJ", idForBase: "oilGj" },
    { label: "Wood (GJ)", value: "woodGJ", idForBase: "woodGj" },
    {
        label: "F280 Heat Loss (W)",
        value: "f280HeatLossW",
        idForBase: "f280DesignHeatLoss",
        convert: true,
        convertTo: "W",
    },
    {
        label: "F280 Heat Gain (W)",
        value: "f280HeatGainW",
        idForBase: "f280DesignHeatGain",
        convert: true,
        convertTo: "W",
    },
    {
        label: "Auxiliary Heating Energy (MJ)",
        value: "auxHeatingMJ",
        idForBase: "auxiliaryEnergy",
        convert: true,
        convertTo: "MJ",
    },
    { label: "Gross Heat Loss (GJ)", value: "grossHeatLossGJ", idForBase: "grossHeatLoss" },
    { label: "TEDI (kWh/m2)", value: "tedi", idForBase: "thermalEnergyDemandIntensity" },
    { label: "MEUI (kWh/m2)", value: "meui", idForBase: "mechanicalEnergyUseIntensity" },
    { label: "EUI (GJ/m2)", value: "eui", idForBase: "energyUseIntensity" },
    {
        label: "Electric Consumption (GJ)",
        value: "totalElecEmissionsTCO2",
        idForBase: "totalElecEmissionsTCO2",
        ignore: true,
    },
    {
        label: "Total Emissions (tCO₂/y)",
        value: "totalEmissionsTCO2",
        idForBase: "totalEmissionsTCO2",
        ignore: true,
    },
    {
        label: "Heat Loss Reduction vs ERS Ref",
        value: "heatLossReductionVSERSRef",
        idForBase: "heatLossReductionVSERSRef",
    },
    {
        label: "Overall Energy Performance Improvement vs ERS Ref.",
        value: "overallEnergyPerfVSERSRef",
        idForBase: "overallEnergyPerfVSERSRef",
    },
    { label: "Total Cost Of The Measures ($)", value: "totalUpgradeCost", idForBase: "totalUpgradeCost" },
];
