import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import { useOutsideClickHook } from "utils/outsideClick";

import ThreeDots from "assets/images/icons/JSX/ThreeDots";
import Dropdown from "components/Dropdown";

import classes from "../style.module.scss";

import nestedArrow from "assets/images/icons/nestedArrow.svg";

const NestedPath = ({
    path,
    folders,
    setFolderToDelete,
    setFolderToRename,
    setFolderToMove,
    setFolderToShare,
    onDragOver,
    setModelsToSummarize,
}) => {
    const [isFolderOptionsOpen, setIsFolderOptionsOpen] = useState(false);

    const folderOptionsRef = useOutsideClickHook(() => setIsFolderOptionsOpen(false));

    return (
        <div className={classes.nestedPathsWrapper}>
            <div
                className={classes.pathTextContainer}
                onDragOver={(event) => {
                    event.preventDefault();
                    onDragOver(event, null, "folder");
                }}
            >
                <Link
                    to={"/dashboard"}
                    className={classes.pathText}
                    style={{
                        color: path.length > 0 ? "#65676a" : "#262e3f",
                    }}
                >
                    Model Directory
                </Link>
            </div>
            {path.length > 0 &&
                path.map((id, index) => (
                    <Fragment key={index}>
                        <img src={nestedArrow} alt={nestedArrow} style={{ marginTop: "6px" }} />
                        <div
                            className={classes.pathTextContainer}
                            ref={path.length - 1 === index ? folderOptionsRef : null}
                            onClick={() => path.length - 1 === index && setIsFolderOptionsOpen(!isFolderOptionsOpen)}
                            onDragOver={(event) => {
                                event.preventDefault();
                                onDragOver(event, id, "folder");
                            }}
                        >
                            <Link
                                className={classes.pathText}
                                to={`/dashboard/${path
                                    .slice(0, index + 1)
                                    .join("/")
                                    .toString()}`}
                                style={{
                                    color: path.length - 1 === index ? "#262e3f" : "#65676a",
                                }}
                            >
                                {folders[id].name}
                            </Link>
                            {path.length - 1 === index && !folders[id].disableDelete && (
                                <div>
                                    <ThreeDots />
                                    <Dropdown
                                        className={classes.pathTextDropdown}
                                        open={isFolderOptionsOpen}
                                        options={[
                                            {
                                                label: "Rename",
                                                onClick: () => {
                                                    setFolderToRename(id);
                                                    setIsFolderOptionsOpen(false);
                                                },
                                            },
                                            {
                                                label: "Move Folder to",
                                                onClick: () => {
                                                    setIsFolderOptionsOpen(false);
                                                    setFolderToMove(id);
                                                },
                                            },
                                            {
                                                label: "Share Folder",
                                                onClick: () => {
                                                    setFolderToShare(id);
                                                    setIsFolderOptionsOpen(false);
                                                },
                                            },
                                            {
                                                label: "Export Results",
                                                onClick: () => {
                                                    setIsFolderOptionsOpen(false);
                                                    setModelsToSummarize();
                                                },
                                            },
                                            {
                                                label: "Delete",
                                                onClick: () => {
                                                    setFolderToDelete(id);
                                                    setIsFolderOptionsOpen(false);
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                            )}
                            {path.length - 1 === index && folders[id].disableDelete && (
                                <div>
                                    <ThreeDots />
                                    <Dropdown
                                        className={classes.pathTextDropdown}
                                        open={isFolderOptionsOpen}
                                        options={[
                                            {
                                                label: "Export Results",
                                                onClick: () => {
                                                    setIsFolderOptionsOpen(false);
                                                    setModelsToSummarize();
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                            )}
                        </div>
                    </Fragment>
                ))}
        </div>
    );
};

export default NestedPath;
