import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { storageRef } from "_firebase";

import userActions from "store/users/actions";

import uploadSvg from "assets/images/icons/uploadSvg.svg";

import classes from "./style.module.scss";

const { updateOrganization } = userActions;

const UploadLogo = ({ organizationDetails, orgId, uid, updateOrganization, uploadState, setUploadState }) => {
    const ref = useRef(null);

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.type === "dragenter") {
            setUploadState({ ...uploadState, isDraggingOver: true });
        } else {
            setUploadState({ ...uploadState, isDraggingOver: false });
        }
    };

    useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener("dragenter", handleDragOver);
            ref.current.addEventListener("dragleave", handleDragOver);
        }

        return () => {
            if (ref.current) {
                ref.current.removeEventListener("dragenter", handleDragOver);
                ref.current.removeEventListener("dragleave", handleDragOver);
            }
            // ref.current.removeEventListener("drop", );
        };
    }, []);

    const handleUpload = (e, isDropdown) => {
        e.preventDefault();

        document.body.style.cursor = "progress";

        setUploadState({ ...uploadState, isDraggingOver: false, dropError: false, isUploading: true });

        const { files } = isDropdown ? e.dataTransfer : e.target;

        const file = files[0];

        if (!file.type.includes("image")) {
            setUploadState({ ...uploadState, dropError: true });
            return;
        }

        const uploadTask = storageRef.child(`${uid}/organization/${orgId}/${file.name}`).put(file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

                console.log("Upload is " + progress + "% done");
                setUploadState({ ...uploadState, progress, isUploading: true });
            },
            (error) => {
                console.log(error);
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
                    await updateOrganization(orgId, {
                        ...organizationDetails,
                        orgMeta: { ...organizationDetails.orgMeta, logoUrl: downloadURL },
                    });

                    setUploadState({ ...uploadState, isUploading: false });
                });
            }
        );
    };

    return (
        <div
            ref={ref}
            className={`${classes.uploadLogoContainer}`}
            style={{
                border:
                    uploadState.isDraggingOver || uploadState.isUploading ? "1px solid #62BCF8" : "1px dashed #62BCF8",
                backgroundColor: uploadState.isDraggingOver || uploadState.isUploading ? "#FFFFFF" : "",
            }}
            onDrop={(e) => handleUpload(e, true)}
        >
            {uploadState.isUploading ? (
                <>
                    <p style={{ fontSize: "0.75rem" }}>
                        Uploading <b>{uploadState.progress}%</b>
                    </p>
                    <div className={classes.loaderWrapper}>
                        <div className={classes.loader} style={{ width: `${uploadState.progress}%` }}></div>
                    </div>
                </>
            ) : (
                <>
                    <div className={classes.dropIconTextWrapper}>
                        <img src={uploadSvg} alt="Upload icon" />
                        <p>
                            Drag and drop or <span style={{ color: "#0049C6" }}>browse files</span>
                        </p>
                    </div>
                    <span className={`${classes.dropSubText}`}>(.jpeg, .png, .svg)</span>
                    <input
                        className={classes.dragAndDropInput}
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleUpload(e, false)}
                    />
                </>
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    updateOrganization: (orgId, orgData) => dispatch(updateOrganization(orgId, orgData)),
});

export default connect(null, mapDispatchToProps)(UploadLogo);
