import React, { useState } from "react";

import { addUsersToOrganizationAPI } from "utils/organization/api";

import Dialog, { CloseDialog } from "components/Dialog";
import Input from "components/Input";
import Button from "components/Button";

import classes from "./style.module.scss";

const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

const AddMembersModal = ({ open, onClose, orgId, uid, fetchUserOrganization, setAlert }) => {
    const [loading, setLoading] = useState(false);
    const [emails, setEmails] = useState("");
    const [error, setError] = useState(false);

    const handleAddMembers = async (e) => {
        e.preventDefault();

        setError(false);
        setLoading(true);

        document.body.style.cursor = "progress";

        const emailsArray = emails.split(";");

        if (emailsArray.some((email) => !regex.test(email))) {
            setError(true);
            setLoading(false);

            document.body.style.cursor = "default";

            return;
        }

        const res = await addUsersToOrganizationAPI({
            orgId,
            addedBy: uid,
            usersToInvite: emailsArray,
        });

        const { data: { hasErrors = false } = {} } = res || {};

        await fetchUserOrganization();

        document.body.style.cursor = "default";

        setLoading(false);

        if (hasErrors)
            setAlert({
                open: true,
                message: "Some emails could not be added",
                anchorOrigin: { vertical: "top", horizontal: "center" },
                direction: "down",
                severity: "warning",
            });
        else
            setAlert({
                open: true,
                message: "Members added successfully",
                anchorOrigin: { vertical: "top", horizontal: "center" },
                direction: "down",
                severity: "success",
            });

        setEmails("");
        onClose();
    };

    return (
        <Dialog open={open}>
            <CloseDialog closeActions={onClose} />
            <div className={classes.dialog}>
                <h3>Add Members</h3>
                <span className={classes.subText}>
                    You can invite more than one member by entering each email address, separated by a semicolon.
                </span>
                <Input
                    input={{
                        value: emails,
                        onChange: (e) => {
                            setEmails(e);
                            setError(false);
                        },
                    }}
                    disabled={loading}
                />
                {error && <span className={classes.errorText}>All emails should be valid</span>}
                <div className={classes.footer}>
                    <Button type="red" onClick={() => onClose()}>
                        Cancel
                    </Button>
                    <Button disabled={loading || error} onClick={handleAddMembers}>
                        {loading ? "Adding..." : "Add"}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default AddMembersModal;
