import React, { useState } from "react";
import classes from "./style.module.scss";
import Avatar from "assets/images/icons/JSX/Avatar_Temp";
import HelpIcon from "assets/images/icons/JSX/Help";
import BellIcon from "assets/images/icons/JSX/Bell";
import Dropdown from "components/Dropdown";
import ArrowIcon from "assets/images/icons/JSX/arrow-down";
import { useOutsideClickHook } from "utils/outsideClick";
import { auth } from "_firebase";
import { mixpanel } from "components/Mixpanel";
import { isEmpty } from "lodash";

const ResourceNav = ({ open, history, close }) => (
    <Dropdown
        open={open}
        className={classes.resourceDropdown}
        options={[
            {
                label: "Quick Start",
                onClick: () => {
                    window.open("/resources/quickstart", "_blank", "noopener noreferrer");
                    mixpanel.track("Resource Nav Click", {
                        Name: "Quick Start",
                    });
                    close();
                },
            },
            {
                label: "FAQs",
                onClick: () => {
                    window.open("/resources/faqs", "_blank", "noopener noreferrer");
                    mixpanel.track("Resource Nav Click", {
                        Name: "FAQs",
                    });
                    close();
                },
            },
            {
                label: "Contact Us",
                onClick: () => {
                    window.open("/resources/contact", "_blank", "noopener noreferrer");
                    mixpanel.track("Resource Nav Click", {
                        Name: "Contact Us",
                    });
                    close();
                },
            },
            {
                label: "Product Roadmap",
                onClick: () => {
                    window.open("/resources/roadmap", "_blank", "noopener noreferrer");
                    mixpanel.track("Resource Nav Click", {
                        Name: "Product Roadmap",
                    });
                    close();
                },
            },
            {
                label: "Terms and Conditions",
                onClick: () => {
                    window.open(
                        "https://drive.google.com/file/d/1cxMLeiix0V5aJe80PTMAyeJeF-eKrUKY/view?usp=sharing",
                        "_blank",
                        "noopener noreferrer"
                    );
                    mixpanel.track("Resource Nav Click", {
                        Name: "Terms and Conditions",
                    });
                    close();
                },
                hasDivider: true,
            },
            {
                label: "Privacy Policy",
                onClick: () => {
                    window.open(
                        "https://drive.google.com/file/d/1uCB8yeByjenPiu-GdSJN1d5mmLCTIvWp/view?usp=sharing",
                        "_blank",
                        "noopener noreferrer"
                    );
                    mixpanel.track("Resource Nav Click", {
                        Name: "Privacy Policy",
                    });
                    close();
                },
            },
        ]}
    />
);

const UserNav = ({ open, toggleUserNav, history, organizations, primaryOrg }) => {
    const currentOrg = primaryOrg ? primaryOrg : !isEmpty(organizations) ? Object.keys(organizations)[0] : "";

    return (
        <Dropdown
            open={open}
            className={classes.userDropdown}
            options={[
                {
                    label: "Preferences",
                    onClick: () => {
                        history.push("/user/preferences");
                        toggleUserNav(false);
                    },
                },
                {
                    label: "Account",
                    onClick: () => {
                        history.push("/user/account");
                        toggleUserNav(false);
                    },
                },
                {
                    label: "Organization",
                    onClick: () => {
                        history.push(`/user/organization${currentOrg ? `/${currentOrg}` : ""}`);
                        toggleUserNav(false);
                    },
                    disabled: !currentOrg,
                },
                {
                    label: "Logout",
                    onClick: () => {
                        mixpanel.track("Logout");
                        mixpanel.reset();
                        auth.signOut();
                    },
                },
            ]}
        />
    );
};

export default ({ name, modelName, history, organizations, primaryOrg }) => {
    const [openResourceNav, toggleResourceNav] = useState(false);
    const [openUserNav, toggleUserNav] = useState(false);
    const resourceRef = useOutsideClickHook(() => toggleResourceNav(false));
    const userRef = useOutsideClickHook(() => toggleUserNav(false));

    return (
        <div className={classes.topBar}>
            {
                // modelName &&
                //     <div className={classes.breadCrumb}>
                //         <span>House Models</span>
                //         <ArrowDown />
                //         <span>{modelName}</span>
                //     </div>
            }
            <div className={classes.updates} ref={resourceRef}>
                <p onClick={() => history.push("/updates")}>
                    <BellIcon /> Updates
                </p>
            </div>
            <div className={classes.resourceNav} ref={resourceRef}>
                <p className={openResourceNav ? classes.open : ""} onClick={() => toggleResourceNav(!openResourceNav)}>
                    <HelpIcon /> Resources{" "}
                    <span className={classes.arrow}>
                        <ArrowIcon />
                    </span>
                </p>
                <ResourceNav open={openResourceNav} history={history} close={() => toggleResourceNav(false)} />
            </div>
            <div className={classes.userNav} ref={userRef}>
                <Avatar />
                <p className={openUserNav ? classes.open : ""} onClick={() => toggleUserNav(!openUserNav)}>
                    {name}
                </p>
                <UserNav
                    open={openUserNav}
                    toggleUserNav={toggleUserNav}
                    history={history}
                    organizations={organizations}
                    primaryOrg={primaryOrg}
                />
            </div>
        </div>
    );
};
