import React from "react";

import Toggle from "components/Input/Toggle";
import Tooltip from "components/Tooltip";
import LoadingDots from "components/LoadingDots";

import ParametricPassIcon from "assets/images/icons/ParametricPass.svg";
import ParametricFailIcon from "assets/images/icons/ParametricFail.svg";
import EditIcon from "assets/images/icons/pencil.svg";
import RemoveIcon from "assets/images/icons/circleRemove.svg";

import classes from "../ParametricUpgradesTableBody/styles.module.scss";
import sharedClasses from "../styles.module.scss";

const ParameticTableRow = ({
    upgradePackage,
    upgrade,
    upgradeType,
    toggleUpgradeInParametricAnalysis,
    removeUpgradeFromParametricAnalysis,
    toggleUpgrade,
    setUpgradeWhereToAddCode,
    isValidationRunComplete = true,
    analysisId,
}) => {
    const { packageValues: { upgradeProcess = "" } = {} } = upgrade || {};
    const canBeEdited = !upgrade.firstBatchId;

    // const isPreCheckRunning = true;
    const isPreCheckRunning = !isValidationRunComplete && isValidationRunComplete !== null;

    const upgradeCost =
        upgradePackage === "None"
            ? 0
            : upgrade.packageValues?.cost
            ? upgrade.packageValues?.cost[upgrade.packageValues.upgradeSubType]?.total || 0
            : 0;

    return upgrade.label ? (
        <div
            key={upgradePackage}
            className={`${sharedClasses.parametricUpgradesTableRow} 
    ${sharedClasses.parametricUpgradesTableRowBorderTop}`}
            // style={{ pointerEvents: upgrade.firstBatchId ? "none" : "auto" }}
        >
            <div
                className={`${sharedClasses.parametricUpgradesTableRowCell}
        ${sharedClasses.parametricUpgradesTableRowCellMainPadding}`}
                style={{ maxWidth: "680px", width: "100%" }}
            >
                <span>{upgrade.label}</span>
            </div>
            <div
                className={`${sharedClasses.parametricUpgradesTableRowCell} 
       
        ${sharedClasses.parametricUpgradesTableRowBorderLeft} 
        ${sharedClasses.parametricUpgradesTableRowCellSmallPadding}
        ${sharedClasses.parametricUpgradesTableRowAutoMarginLeft}
        `}
                style={{ maxWidth: "140px", width: "100%" }}
            >
                <span>${parseFloat(upgradeCost).toFixed(2)}</span>
            </div>
            <div
                className={`${sharedClasses.parametricUpgradesTableRowCell} 
            ${sharedClasses.parametricUpgradesTableRowBorderLeft}`}
                style={{
                    padding: "0 32px 0 13px",
                    maxWidth: "310px",
                    width: "100%",
                    gap: "13px",
                }}
            >
                <div className={classes.passFailWrapper} style={{ maxWidth: "156px", width: "100%" }}>
                    {upgradePackage === "None" ? (
                        upgrade.passed ? (
                            <>
                                <img src={ParametricPassIcon} alt="Pass" />
                                <span
                                    className={`${sharedClasses.parametricUpgradesTableRowCellText} ${classes.passFailForNone}`}
                                >
                                    <span className={classes.gjText}>GJ:</span>-
                                </span>
                            </>
                        ) : (
                            <>&nbsp;</>
                        )
                    ) : isPreCheckRunning && upgrade.active && upgrade.passed === null ? (
                        <LoadingDots />
                    ) : upgrade.passed ? (
                        <>
                            <img src={ParametricPassIcon} alt="Pass" />
                            <span className={`${sharedClasses.parametricUpgradesTableRowCellText} `}>
                                <span className={classes.gjText}>GJ: </span>
                                {parseFloat(upgrade.individualSavings).toFixed(2)}%
                            </span>
                        </>
                    ) : upgrade.passed === false ? (
                        <>
                            <img src={ParametricFailIcon} alt="Fail" />
                        </>
                    ) : (
                        <>&nbsp;</>
                    )}
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "19px",
                        maxWidth: "96px",
                        width: "100%",
                    }}
                >
                    <div
                        data-html
                        data-tip={`This measure was used to generate results and can no longer be removed`}
                        data-for={upgradePackage}
                    >
                        <Toggle
                            active={upgrade.active}
                            onChange={({ target: { checked } }) => {
                                toggleUpgradeInParametricAnalysis(upgradeType, upgradePackage, checked);
                            }}
                            disabled={!canBeEdited}
                        />
                        {!canBeEdited && <Tooltip id={upgradePackage} />}
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "14px" }}>
                        {upgradePackage !== "None" && (
                            <img
                                className={classes.hoverPointer}
                                src={EditIcon}
                                alt="Edit"
                                onClick={() => {
                                    const initUpgradeData = { fields: upgrade.packageValues.fields };

                                    setUpgradeWhereToAddCode({
                                        ecmInputMapUpgradeType: upgradeType,
                                        ecmInputMapUpgradeId: upgradePackage,
                                        ecmInputMapUpgrade: upgrade.packageValues,
                                    });

                                    toggleUpgrade({
                                        isOpen: true,
                                        formName: "upgrades",
                                        selectedPackage: upgrade.packageValues.parentUpgradeKey,
                                        selectedUpgrade: upgradePackage,
                                        upgradeType: upgradeType,
                                        selectedUpgradeType: upgradeType,
                                        selectedH2kType: upgrade.packageValues.upgradeType,
                                        selectedUpgradeH2kType: upgrade.packageValues.upgradeType,
                                        selectedUpgradeTypeLabel: "",
                                        selectedUpgradeLabel: upgrade.label,
                                        withSelectComponents: false,
                                        initUpgradeData,
                                        accessor: "fields",
                                        isUpgradeLibrary: false,
                                        isParametricAnalysis: true,
                                        canBeEditedFromParametric: canBeEdited && upgradeProcess !== "achPercent",
                                        analysisId,
                                    });
                                }}
                            />
                        )}
                        {upgradePackage !== "None" && canBeEdited && (
                            <img
                                className={classes.hoverPointer}
                                src={RemoveIcon}
                                alt="Delete"
                                onClick={() => {
                                    if (upgrade.firstBatchId) return;

                                    removeUpgradeFromParametricAnalysis(upgradeType, upgradePackage);
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default ParameticTableRow;
