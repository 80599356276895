import React, { useEffect, useState } from "react";

import { changeUsersRolesOrgAPI, removeUserFromOrgAPI } from "utils/organization/api";

import Button from "components/Button";
import Checkbox from "components/Input/Checkbox";
import Select from "components/Input/Select";
import AddMembersModal from "../AddMembersModal";
import TransferCreditsModal from "./TransferCreditsModal";

import VoltaCreditBlue from "assets/images/icons/VoltaCreditBlue.svg";
import classes from "./style.module.scss";

const ROLE_OPTIONS = [
    { value: "admin", label: "Admin" },
    { value: "member", label: "Member" },
];

const MembersList = ({
    membersList,
    adminsList,
    IS_ADMIN,
    uid,
    orgId,
    fetchUserOrganization,
    setAlert,
    isUsingParametricCredits,
    parametricCredits,
    totalCredits,
    updateUser,
}) => {
    const [selectedMembersToRemove, setSelectedMembersToRemove] = useState([]);
    const [adminsListToControl, setAdminsListToControl] = useState([]);
    const [usersToChangeRole, setUsersToChangeRole] = useState([]);
    const [isAddMembersModalOpen, setIsAddMembersModalOpen] = useState(false);
    const [isDeletingMembers, setIsDeletingMembers] = useState(false);
    const [transferCreditsModalData, setTransferCreditsModalData] = useState({
        isOpen: false,
        transferTo: "",
    });

    const sortedMembersList = Object.entries(membersList)
        .sort((a, b) => {
            const { name: nameA = "" } = a[1];
            const { name: nameB = "" } = b[1];

            const lastNameA = nameA.split(" ")[nameA.split(" ").length - 1];
            const lastNameB = nameB.split(" ")[nameB.split(" ").length - 1];

            return lastNameA.localeCompare(lastNameB);
        })
        .sort((a, b) => {
            const isAdminA = adminsListToControl.includes(a[0]);
            const isAdminB = adminsListToControl.includes(b[0]);

            if (isAdminA && !isAdminB) return -1;
            if (!isAdminA && isAdminB) return 1;

            return 0;
        });

    useEffect(() => {
        setAdminsListToControl(adminsList);
    }, [adminsList]);

    const handleRoleSave = async (e) => {
        if (!IS_ADMIN) return;

        e.preventDefault();

        await changeUsersRolesOrgAPI({
            usersToChange: usersToChangeRole,
            changesMadeBy: uid,
            orgId: orgId,
        });

        await fetchUserOrganization();

        setAlert({
            open: true,
            message: "Roles saved successfully",
            severity: "success",
        });
    };

    const handleMemberRemove = async (e) => {
        if (!IS_ADMIN) return;

        e.preventDefault();

        setIsDeletingMembers(true);

        await removeUserFromOrgAPI({
            usersToRemove: selectedMembersToRemove,
            removedBy: uid,
            orgId: orgId,
        });

        await fetchUserOrganization();

        setSelectedMembersToRemove([]);
        setAlert({
            open: true,
            message: "Members removed successfully",
            severity: "success",
        });

        setIsDeletingMembers(false);
    };

    const handleRoleChange = (e, name, key) => {
        if (e === "admin" && !adminsListToControl.includes(key)) {
            setUsersToChangeRole([
                ...usersToChangeRole,
                { id: key, oldRole: "member", newRole: "admin", userName: name },
            ]);

            setAdminsListToControl([...adminsListToControl, key]);
        }

        if (e === "member" && adminsListToControl.includes(key)) {
            setUsersToChangeRole([
                ...usersToChangeRole,
                { id: key, oldRole: "admin", newRole: "member", userName: name },
            ]);

            setAdminsListToControl(adminsListToControl.filter((id) => id !== key));
        }

        if (e === usersToChangeRole.find(({ id }) => id === key)?.oldRole) {
            setUsersToChangeRole(usersToChangeRole.filter(({ id }) => id !== key));

            if (e === "admin") {
                setAdminsListToControl([...adminsListToControl, key]);
            }
        }
    };

    return (
        <div className={classes.membersListContainer}>
            {IS_ADMIN && (
                <div className={classes.buttonContainer}>
                    <Button type="lightGrey" small smallPadding onClick={() => setIsAddMembersModalOpen(true)}>
                        Add Members
                    </Button>
                    <div style={{ marginLeft: "auto" }}>
                        <Button
                            type="red"
                            small
                            smallPadding
                            className={classes.removeButton}
                            disabled={selectedMembersToRemove.length === 0 || isDeletingMembers}
                            onClick={handleMemberRemove}
                        >
                            {isDeletingMembers ? "Removing..." : `Remove ${selectedMembersToRemove.length} member(s)`}
                        </Button>
                        <Button small smallPadding disabled={usersToChangeRole.length === 0} onClick={handleRoleSave}>
                            Save Changes
                        </Button>
                    </div>
                </div>
            )}

            <div className={classes.membersListWrapper}>
                {sortedMembersList.map(([key, { name }]) => (
                    <div key={key} className={classes.member}>
                        {IS_ADMIN && (
                            <Checkbox
                                className={classes.fixedCheckBox}
                                input={{
                                    value: selectedMembersToRemove.map(({ id }) => id).includes(key),
                                    onChange: () =>
                                        selectedMembersToRemove.map(({ id }) => id).includes(key)
                                            ? setSelectedMembersToRemove(
                                                  selectedMembersToRemove.filter(({ id }) => id !== key)
                                              )
                                            : setSelectedMembersToRemove([
                                                  ...selectedMembersToRemove,
                                                  { id: key, userName: name },
                                              ]),
                                }}
                            />
                        )}{" "}
                        <span style={{ flex: 1, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {name}
                        </span>
                        <Select
                            className={classes.roleSelector}
                            options={ROLE_OPTIONS}
                            input={{
                                value: adminsListToControl.includes(key) ? "admin" : "member",
                                onChange: (e) => handleRoleChange(e, name, key),
                            }}
                            disabled={key === uid || !IS_ADMIN}
                        />
                        {IS_ADMIN && (
                            <Button
                                icon={() => <img src={VoltaCreditBlue} alt="VoltaCredit" />}
                                onClick={(e) => {
                                    e.preventDefault();

                                    setTransferCreditsModalData({
                                        isOpen: true,
                                        transferTo: key,
                                    });
                                }}
                                type="hollow"
                            ></Button>
                            // <span
                            //     className={classes.transferIcon}
                            //     onClick={(e) => {
                            //         e.preventDefault();

                            //         setTransferCreditsModalData({
                            //             isOpen: true,
                            //             transferTo: key,
                            //         });
                            //     }}
                            // >
                            //     $
                            // </span>
                        )}
                    </div>
                ))}
            </div>
            <AddMembersModal
                open={isAddMembersModalOpen}
                onClose={() => setIsAddMembersModalOpen(false)}
                uid={uid}
                orgId={orgId}
                fetchUserOrganization={fetchUserOrganization}
                setAlert={setAlert}
            />
            {IS_ADMIN && (
                <TransferCreditsModal
                    isOpen={transferCreditsModalData.isOpen}
                    closeModal={() =>
                        setTransferCreditsModalData({
                            isOpen: false,
                            transferTo: "",
                        })
                    }
                    transferTo={transferCreditsModalData.transferTo}
                    credits={isUsingParametricCredits ? parametricCredits : totalCredits}
                    transferToName={membersList[transferCreditsModalData.transferTo]?.name}
                    setAlert={setAlert}
                    transferFrom={uid}
                    organizationId={orgId}
                    updateUser={updateUser}
                />
            )}
        </div>
    );
};

export default MembersList;
