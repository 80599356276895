import React from "react";

export default ({ fill = "#979797" }) => (
    <svg
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill={fill} fillRule="nonzero">
            <path
                d="M12,-2.71782596e-13 L12,1.992 L1.99790764,1.99227476 L1.99790764,16.0133982 L12,16.013 L12,18 L-1.97175609e-13,18 L-1.97175609e-13,-2.71782596e-13 L12,-2.71782596e-13 Z M8.36099988,4.18178833 L9.77600803,5.59679648 L7.23833915,8.13622529 L18,8.13501341 L18,10.1350134 L7.23268229,10.1359233 L9.7748438,12.6776962 L8.36750702,14.085033 L3.41263112,9.13015709 L8.36099988,4.18178833 Z"
                id="Rectangle"
                transform="translate(9.000000, 9.000000) rotate(-90.000000) translate(-9.000000, -9.000000) "
            ></path>
        </g>
    </svg>
);
