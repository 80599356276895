import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    body: {
        width: "100%",
        padding: "20px",
        height: "100vh",
    },
    topTextContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "space-between",
        marginBottom: "40px",
        position: "relative",
    },
    table: {
        display: "flex",
        flexDirection: "column",
    },
    tableRow: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        border: "1px solid #e1eaf0",
    },
    nestedTable: {
        display: "flex",
        flexDirection: "column",
    },
    tableCell: {
        display: "flex",
        // flex: "1",
        alignItems: "center",
        flexDirection: "row",
        fontSize: "10px",
        flex: "1",
        borderRight: "1px solid #e1eaf0",
        padding: "4px 30px 4px 4px",
        minHeight: "30px",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    existingHomeTableCell: {
        backgroundColor: "#f3f6f7",
    },
    tableHeaderText: {
        fontSize: "10px",
        fontFamily: "Helvetica-Bold",
    },
    topText: {
        fontSize: "10px",
        fontFamily: "Helvetica-Bold",
    },
    graphHeadingContainer: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
    },
    graphSquare: {
        height: "10px",
        width: "10px",
        marginRight: "4px",
    },
    singleLine: {
        minHeight: "20px",
    },
});
