import { DashboardAPI } from "utils/api";

export const addUsersToOrganizationAPI = async ({ usersToInvite = [], addedBy = "", orgId = "" }) =>
    DashboardAPI({
        method: "POST",
        url: "/addUsersToOrganization",
        data: {
            usersToInvite,
            addedBy,
            orgId,
        },
    });

export const renameOrganizationAPI = async ({ orgId = "", newName = "", changeBy = "" }) =>
    DashboardAPI({
        method: "POST",
        url: "/renameOrganization",
        data: {
            orgId,
            newName,
            changeBy,
        },
    });

export const removeUserFromOrgAPI = async ({ usersToRemove = [], removedBy = "", orgId = "" }) =>
    DashboardAPI({
        method: "POST",
        url: "/removeUsersFromOrganization",
        data: {
            usersToRemove,
            removedBy,
            orgId,
        },
    });

export const changeUsersRolesOrgAPI = async ({ usersToChange = [], changesMadeBy = "", orgId = "" }) =>
    DashboardAPI({
        method: "POST",
        url: "/changeUsersRolesOrganization",
        data: {
            usersToChange,
            changesMadeBy,
            orgId,
        },
    });

export const getOrgLogoAPI = async ({ orgId = "" }) => {
    const res = await DashboardAPI({
        method: "GET",
        url: `/getOrgLogo/${orgId}`,
        responseType: "arraybuffer",
    });

    const { data } = res;
    const contentType = res.headers["content-type"];

    const base64String = Buffer.from(data, "binary").toString("base64");

    // console.log(base64String);

    return { base64String, contentType };
};
